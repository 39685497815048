import _isNil from 'lodash/isNil';

import type { ProfileModel } from '../../types';

export function isUerAllowedToAccessProtectedRoutes(
  userProfile: ProfileModel | null
) {
  if (_isNil(userProfile) || userProfile.emailVerified) {
    return true;
  }

  if (!userProfile.emailVerified) {
    return false;
  }

  return userProfile.linkExpCountDown > 0;
}
