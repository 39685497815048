import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { Ref, forwardRef } from 'react';
import { VscTriangleDown } from 'react-icons/vsc';

import { sendEventToGTMType } from '../../../types';
import { ErrorPopoverPositioned } from '../../SimpleRule/Error/ErrorPopoverPositioned';
import { ContainerStyled, OperatorText } from './TableNodes.styled';

type OperatorsLauncherProps = {
  error?: string | null;
  text?: string;
  dataType?: string | null;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const OperatorsLauncher = forwardRef(
  (
    {
      text = 'Operator',
      error,
      dataType,
      handleSendEventToGTM,
    }: OperatorsLauncherProps,
    ref: Ref<HTMLElement>
  ) => {
    const handleClick = () => {
      if (typeof handleSendEventToGTM === 'function') {
        handleSendEventToGTM({
          action: text === 'Operator' ? 'add' : 'edit',
          element: 'property',
          actionName: dataType ?? '',
        });
      }
    };

    return (
      <ContainerStyled padding={[3, 8]} ref={ref} onClick={handleClick}>
        {!_isNil(error) && <ErrorPopoverPositioned error={error} />}

        <Inline align="center" stretch="start">
          <OperatorText name="paragraphSmall">{!_isEmpty(text) ? text : 'Operator'}</OperatorText>
          ▾
        </Inline>
      </ContainerStyled>
    );
  }
);

OperatorsLauncher.displayName = 'OperatorsLauncher';
