import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { ReactElement, useRef } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { HiDotsVertical } from 'react-icons/hi';
import { IoMdExit } from 'react-icons/io';
import { IoExitOutline } from 'react-icons/io5';
import { MdOutlineClosedCaptionDisabled } from 'react-icons/md';
import { Menu, MenuItem, PopoverMethods, Typography, useLayer } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { TrashIcon } from '../../../../../components/icons/Trash';
import { generateUid } from '../../../../../utils/common';
import { HasSameParentType } from '../../../models';
import { sendEventToGTMType } from '../../../types';
import { getGroupNode, getRequiredKey } from '../../../utils/common';
import {
  deleteDecisionTableNodes,
  getIfHasParentGroup,
} from '../../../utils/decisionTable';
import { decisionTableNodesAtom } from '../DecisionTable';
import { DecisionTableNodesModel, DecisionTableRow } from '../models';
import { PropertyMenuItem } from './DecisionTableBlock.styled';
import { DeleteConfirmModal } from './DeleteConfirmModal';

type PropertyActionsProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
  index: number;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
  isGroup?: boolean;
  indexes?: number[];
  groupKey?: string;
  hasSameParent?: HasSameParentType;
  canJoin?: boolean;
  canLeave?: boolean;
  canRemove?: boolean;
  canUnGroup?: boolean;
};

export function PropertyActions({
  control,
  setValue,
  index,
  handleSendEventToGTM,
  isGroup = false,
  indexes = [],
  groupKey,
  hasSameParent,
  canJoin = false,
  canLeave = false,
  canRemove = true,
  canUnGroup = false,
}: PropertyActionsProps) {
  const properties = useWatch({ name: 'properties', control });
  const rows: Array<Record<string, DecisionTableRow>> = useWatch({
    name: 'rows',
    control,
  });

  const menuRef = useRef<PopoverMethods>(null);

  const [nodes, setNodes] = useAtom(decisionTableNodesAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const currentProperty = properties[index];
  const currentPropertyKey = getRequiredKey(currentProperty, ['id']);

  /**
   * @function handleDeletePropertyColumn removes the specific property selected to be deleted and also
   * it's corresponding conditions in the rows
   */
  const handleDeletePropertyColumn = () => {
    if (properties.length > 1) {
      // Create a copy of rows to collect all updates
      const updatedRows = [...rows];

      rows?.forEach((row: any, rowIndex: number) => {
        const rowKey = getRequiredKey(row, ['id']);
        const rowValues = structuredClone(row[rowKey]);

        let ruleConditions = [];

        if (!_isNil(rowValues)) {
          ruleConditions = rowValues.condition.filter(
            (condition: any, conditionIndex: number) => {
              if (conditionIndex === index) {
                const conditionKey = getRequiredKey(condition, ['id', 'rhs']);
                setNodes((prev) =>
                  deleteDecisionTableNodes(conditionKey, prev)
                );
              }

              return conditionIndex !== index;
            }
          );

          // Update in the copied array instead of individual setValue
          updatedRows[rowIndex] = {
            ...row,
            [rowKey]: {
              ...rowValues,
              condition: ruleConditions,
            },
          };
        }
      });

      // Single batch update for all rows
      setValue('rows', updatedRows);

      setValue(
        'properties',
        properties.filter(
          (res: any, resultIndex: number) => resultIndex !== index
        )
      );

      setNodes((prev) => deleteDecisionTableNodes(currentPropertyKey, prev));

      if (typeof handleSendEventToGTM === 'function') {
        handleSendEventToGTM({
          action: 'delete',
          element: 'condition',
          actionName: '',
        });
      }
    }
  };

  /**
   * @function handleDeleteGroup removes the group and it's corresponding columns from the DT.
   */
  const handleDeleteGroup = (indexes: number[]) => {
    if (properties.length > 1) {
      // Batch node updates for properties
      indexes.forEach((i) => {
        const currentProperty = properties[i];
        const currentPropertyKey = getRequiredKey(currentProperty, ['id']);
        setNodes((prev) => deleteDecisionTableNodes(currentPropertyKey, prev));

        if (typeof handleSendEventToGTM === 'function') {
          handleSendEventToGTM({
            action: 'delete',
            element: 'condition',
            actionName: '',
          });
        }
      });

      // Create copy of rows to collect all updates
      const updatedRows = [...rows];

      rows?.forEach((row: any, rowIndex: number) => {
        const rowKey = getRequiredKey(row, ['id']);
        const rowValues = structuredClone(row[rowKey]);

        setNodes((prev) => {
          const doesHaveParent = getIfHasParentGroup(
            rows,
            prev,
            indexes[0],
            rowIndex
          );

          return deleteDecisionTableNodes(doesHaveParent.group ?? '', prev);
        });

        let ruleConditions = [];

        if (!_isNil(rowValues)) {
          ruleConditions = rowValues.condition.filter(
            (condition: any, conditionIndex: number) => {
              if (indexes.includes(conditionIndex)) {
                const conditionKey = getRequiredKey(condition, ['id', 'rhs']);
                setNodes((prev) =>
                  deleteDecisionTableNodes(conditionKey, prev)
                );
              }

              return !indexes.includes(conditionIndex);
            }
          );

          // Update in the copied array instead of individual setValue
          updatedRows[rowIndex] = {
            ...row,
            [rowKey]: {
              ...rowValues,
              condition: ruleConditions,
            },
          };
        }
      });

      // Single batch update for all rows
      setValue('rows', updatedRows);

      setValue(
        'properties',
        properties.filter(
          (res: any, resultIndex: number) => !indexes.includes(resultIndex)
        )
      );
    }
  };

  /**
   * @function handleConvertIntoGroup converts the set of conditions into a group corresponding
   * to it's specific row ( which is a group in itself )
   */
  const handleConvertIntoGroup = () => {
    setNodes((prev) => {
      const currentNodes = JSON.parse(
        JSON.stringify(prev)
      ) as unknown as Record<string, DecisionTableNodesModel>;

      rows.forEach((row: any) => {
        const rowKey = getRequiredKey(row, ['id']);
        const newGroupId = generateUid('rule_');

        const conditionKey = getRequiredKey(row[rowKey].condition[index], [
          'id',
        ]);

        currentNodes[conditionKey].parent = newGroupId;

        currentNodes[newGroupId] = getGroupNode(rowKey, [conditionKey], 'and');

        currentNodes[rowKey].children = currentNodes[rowKey].children?.reduce<
          string[]
        >((acc, curr) => {
          if (curr === conditionKey) {
            return [...acc, newGroupId];
          }

          return [...acc, curr];
        }, []);
      });

      return currentNodes;
    });
  };

  /**
   * @function handleRemoveFromGroup removes the specified column from the group and it's corresponding
   * row conditions are also transferred to the parent group ( row )
   */
  const handleRemoveFromGroup = () => {
    // 1. **** get the index where to put the property ****
    // 2. **** get the index where to put the conditions (by row) ****
    // 3. **** remove the condition from the children of the group ****
    // 4. **** append the condition as a child of the row ****
    // 5. **** change the parent of the condition to rowKey (the group ie row) ****

    const propertyToPutAt = hasSameParent?.siblingIndex;

    properties.splice(index, 1);
    properties.splice(propertyToPutAt ?? 0, 0, currentProperty);

    rows.forEach((row: Record<string, DecisionTableRow>, i: number) => {
      const rowKey = getRequiredKey(row, ['id']);
      const rowCondition = row[rowKey].condition;

      const currentCondition = row[rowKey].condition[index];

      const currentConditionKey = getRequiredKey(currentCondition, ['id']);

      rowCondition.splice(index, 1);

      rowCondition.splice(propertyToPutAt ?? 0, 0, currentCondition);

      setNodes((prev) => {
        const curCond = prev[currentConditionKey];
        const parent = prev[currentConditionKey].parent;
        const parentGroup = prev[prev[currentConditionKey].parent];
        const rowGroup = prev[rowKey];
        const rowChildren = prev[rowKey].children;

        rowChildren?.splice(propertyToPutAt ?? 0, 0, currentConditionKey);

        return {
          ...prev,
          [currentConditionKey]: { ...curCond, parent: rowKey },
          [parent]: {
            ...parentGroup,
            children: parentGroup.children?.filter(
              (c) => c !== currentConditionKey
            ),
          },
          [rowKey]: { ...rowGroup, children: rowChildren },
        };
      });

      setValue(`rows.${i}.${rowKey}.condition`, rowCondition);
    });

    setValue('properties', properties);
  };

  const handleUnGroup = () => {
    const sortedIndexes = hasSameParent?.indexes.sort((a, b) => a - b) ?? [];

    rows.forEach((row, rIndex) => {
      const rowKey = getRequiredKey(row, ['id']);

      const currentRow = row[rowKey];
      let group = '';

      const updatedChildren = nodes[rowKey].children;

      for (let i = 0; i < sortedIndexes.length; i++) {
        const currentConditionKey = getRequiredKey(
          currentRow.condition[sortedIndexes[i]],
          ['id']
        );
        group = nodes[currentConditionKey].parent;

        updatedChildren?.splice(sortedIndexes[i], 0, currentConditionKey);

        setNodes((prev) => ({
          ...prev,
          [currentConditionKey]: {
            ...prev[currentConditionKey],
            parent: rowKey,
          },
          [group]: {
            ...prev[group],
            children: [],
          },
        }));
      }

      setNodes((prev) => ({
        ...deleteDecisionTableNodes(group, prev),
        [rowKey]: {
          ...prev[rowKey],
          children: updatedChildren?.filter((c) => c !== group),
        },
      }));
    });
  };

  const { openWithProps: openDeleteModal } = useLayer(
    <DeleteConfirmModal
      onCloseConfirm={handleDeletePropertyColumn}
      title="Delete Condition"
    />
  );

  const listOfMenu: ReactElement[] = [];

  if (canRemove) {
    listOfMenu.push(
      <MenuItem value="delete" key="menu_remove">
        <PropertyMenuItem>
          <Stack>
            <Typography>Remove</Typography>
          </Stack>
          <TrashIcon color="var(--color-black)" />
        </PropertyMenuItem>
      </MenuItem>
    );
  }

  if (canJoin) {
    listOfMenu.push(
      <MenuItem value="join" key="menu_join">
        <PropertyMenuItem>
          <Stack>
            <Inline justify="end">
              <Typography>Turn into Group</Typography>
            </Inline>
            <Typography name="paragraphXs">To nest into a group</Typography>
          </Stack>

          <IoMdExit color="var(--color-black)" />
        </PropertyMenuItem>
      </MenuItem>
    );
  }

  if (canLeave) {
    listOfMenu.push(
      <MenuItem value="leave" key="menu_leave">
        <PropertyMenuItem>
          <Stack>
            <Inline justify="end">
              <Typography>Leave Group</Typography>
            </Inline>
            <Typography name="paragraphXs">
              A group to nest conditions
            </Typography>
          </Stack>

          <IoExitOutline color="var(--color-black)" />
        </PropertyMenuItem>
      </MenuItem>
    );
  }

  if (canUnGroup) {
    listOfMenu.push(
      <MenuItem value="un-group" key="menu_un_group">
        <PropertyMenuItem>
          <Stack>
            <Inline justify="end">
              <Typography>Ungroup</Typography>
            </Inline>
            <Typography name="paragraphXs">
              Release conditions from nest
            </Typography>
          </Stack>

          <MdOutlineClosedCaptionDisabled color="var(--color-black)" />
        </PropertyMenuItem>
      </MenuItem>
    );
  }

  return (
    <Menu
      onMenuItemClick={(val) => {
        if (isGroup && val === 'delete') {
          openDeleteModal({
            onCloseConfirm: () => handleDeleteGroup(indexes),
            title: 'Delete Group',
          });
        } else if (val === 'delete') {
          openDeleteModal({
            onCloseConfirm: handleDeletePropertyColumn,
          });
        } else if (val === 'join') {
          handleConvertIntoGroup();
        } else if (val === 'leave') {
          handleRemoveFromGroup();
        } else if (val === 'un-group') {
          handleUnGroup();
        }

        menuRef.current?.hide();
      }}
      ref={menuRef}
      launcher={
        <span>
          <HiDotsVertical />
        </span>
      }
      disabled={isRuleReadOnly}
    >
      {listOfMenu}
    </Menu>
  );
}
