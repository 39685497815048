import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import { Typography } from 'ui';

export const ContainerStyled = styled(PadBox)`
  min-inline-size: 5rem;
  min-block-size: 2.75rem;
  inline-size: 11rem;
  background-color: var(--color-cyprus);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
`;

export const RuleLauncherContainer = styled(PadBox)`
  max-block-size: 5.5rem;
  background-color: var(--color-solitudeSecondary);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
`;

export const PropertyContainer = styled(Inline)`
  inline-size: 100%;
  overflow-y: hidden;
`;

export const LabelStyled = styled.p`
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const PopoverContainerStyled = styled.div`
  overflow-y: auto;
  max-block-size: 30rem;
  inline-size: 32rem;
`;

export const JsPill = styled(PadBox)`
  min-inline-size: 4rem;
  border-radius: 4px;
  background-color: var(--color-solitude);
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
`;

export const OperatorText = styled(Typography)`
  color: var(--color-british-racing-green);
`;

export const ExcelPill = styled(PadBox)`
  min-inline-size: 4rem;
  border-radius: 4px;
  background-color: var(--color-solitude);
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
`;
