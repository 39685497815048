import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ChangeEvent, Ref, forwardRef, useEffect, useState } from 'react';
import { Dataset, ExecutedValueTooltip, Typography } from 'ui';

import { ErrorPopoverPositioned } from '../../pages/Rules/components/SimpleRule/Error/ErrorPopoverPositioned';
import {
  CorrectValues,
  getPlaceholderByDataType,
  validateRhsValue,
} from '../../pages/Rules/utils/common';
import {
  extractSourceAndAttributeFromValue,
  getPropertyIfExists,
} from '../../utils/common';
import { LIST_KEY_REGEX } from '../../utils/regex';
import {
  InputBrackets,
  InputContainer,
  InputFieldStyled,
} from './DurationLauncher.styled';

type LauncherProps = {
  dataset: Record<string, Dataset>;
  value?: string | number;
  isDisabled?: boolean;
  error?: string | null;
  onClick?: (key: string, value: any) => void;
  updateOnChangeDurationFieldError?: (obj: CorrectValues) => void;
};

export const DurationLauncher = forwardRef(
  (
    {
      value = '',
      dataset,
      isDisabled = false,
      error,
      onClick,
      updateOnChangeDurationFieldError,
    }: LauncherProps,
    ref: Ref<any>
  ) => {
    const [hasError, setHasError] = useState(false);
    const [localValue, setLocalValue] = useState<string | number>('');
    const [nodeType, setNodeType] = useState('constant');

    const { source: originalSource, attribute } =
      extractSourceAndAttributeFromValue(value as string, dataset);

    const toolTipMessage =
      !_isNil(originalSource) &&
      !_isEmpty(originalSource) &&
      !_isNil(dataset[originalSource])
        ? getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                Object.keys(dataset[originalSource]?.attributes ?? {}).reduce(
                  (acc, curr) => {
                    return {
                      ...acc,
                      [curr]:
                        dataset[originalSource ?? ''].attributes[`${curr}`]
                          .executedValue,
                    };
                  },
                  {}
                )
              )
            ) ?? {},
            (localValue as string) ?? ''
          )
        : localValue.toString();

    useEffect(() => {
      setLocalValue(attribute ?? value ?? '');
    }, [attribute]);

    useEffect(() => {
      setNodeType(
        !_isNil(originalSource) && !_isEmpty(originalSource)
          ? 'params'
          : 'constant'
      );
    }, [originalSource]);

    useEffect(() => {
      setHasError(!_isNil(error) && !_isEmpty(error));
    }, [error]);

    const onChange = (e: ChangeEvent<any>) => {
      e.preventDefault();

      // eslint-disable-next-line
      const isList = !!e.target.value.match(LIST_KEY_REGEX)?.length;

      const nodeType = isList ? 'params' : 'constant';

      const result = validateRhsValue(e.target.value, 'numeric', nodeType);

      setNodeType(nodeType);
      setLocalValue(e.target.value);
      setHasError(!result.isCorrect);

      if (typeof updateOnChangeDurationFieldError === 'function') {
        updateOnChangeDurationFieldError(result);
      }
    };

    return (
      <Inline gutter="0.4rem">
        <InputContainer align="center" gutter={0} onClick={() => {}}>
          {!_isNil(error) && <ErrorPopoverPositioned error={error} />}
          {nodeType !== 'constant' && (
            <InputBrackets direction="left">
              <Typography>{'{{'}</Typography>
            </InputBrackets>
          )}

          <ExecutedValueTooltip
            id={'nodeId'}
            attribute={attribute ?? undefined}
            isVisible={nodeType !== 'constant'}
            value={toolTipMessage}
            dataType={'numeric'}
          >
            <InputFieldStyled
              placeholder="0"
              value={localValue}
              ref={ref}
              $hasError={hasError}
              $showExpandIcon={false}
              disabled={isDisabled}
              onChange={onChange}
              onBlur={() => {
                const result = validateRhsValue(
                  localValue,
                  'numeric',
                  nodeType
                );

                if (typeof onClick === 'function') {
                  onClick(
                    'duration',
                    nodeType === 'constant'
                      ? result.value
                      : `{{.${originalSource as string}.${
                          result.value as string
                        }}}`
                  );
                }
              }}
            />
          </ExecutedValueTooltip>

          {nodeType !== 'constant' && (
            <InputBrackets direction="right">
              <Typography>{'}}'}</Typography>
            </InputBrackets>
          )}
        </InputContainer>
      </Inline>
    );
  }
);

DurationLauncher.displayName = 'DurationLauncher';
