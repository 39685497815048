import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import styled from 'styled-components';

export const PropertyHeader = styled.div<{
  padding?: string;
}>`
  min-block-size: 15rem;
  min-inline-size: 35.1rem;
  max-inline-size: 45rem;
  border-right: 1px solid var(--color-DTBorderGrey);
  border-top: 1px solid var(--color-DTBorderGrey);
  border-bottom: 1px solid var(--color-DTBorderGrey);
  border-bottom: none;
  position: relative;

  ${({ padding = null }) => !_isNil(padding) && `padding: ${padding};`}
`;

export const ColumnHeaderContainer = styled(Stack)`
  block-size: 100%;
`;

export const PropertyAddButton = styled.button`
  position: absolute;
  block-size: 2.4rem;
  inline-size: 2.4rem;
  background-color: var(--color-primary2);
  color: var(--color-primary1);
  border-radius: 50%;
  cursor: pointer;
  border: none;
  right: -1.2rem;
  top: 1rem;
  padding: 0;
  z-index: 10;

  &.btn-disabled-interceptor {
    background: var(--color-primary2) !important;
    color: var(--color-primary1) !important;
    cursor: progress !important;
  }
`;

export const ConditionBar = styled(Inline)<{
  $inlineSize?: string;
  $marginTop?: string;
  $zIndex?: string;
  $isGroup?: boolean;
}>`
  padding: 0.2rem 0.8rem;
  border-radius: 4px;
  background: var(--color-blueChalk);
  color: var(--color-christalle);
  position: relative;
  ${({ $inlineSize = '100%' }) => `inline-size: ${$inlineSize};`}
  ${({ $marginTop = '0rem' }) => `margin-top: ${$marginTop};`}
  ${({ $zIndex = '1' }) => `z-index: ${$zIndex};`}
  ${({ $isGroup = false }) =>
    $isGroup && `background: var(--color-paleCornflowerBlue);`}
`;

export const ResultHeader = styled(PadBox)`
  min-block-size: 10rem;
  min-inline-size: 20rem;
  border-right: 1px solid var(--color-DTBorderGrey);
  border-top: 1px solid var(--color-DTBorderGrey);
  border-bottom: 1px solid var(--color-DTBorderGrey);
  border-bottom: none;
  position: relative;
`;

export const ResultBar = styled(Inline)`
  padding: 0.2rem 0.8rem;
  border-radius: 4px;
  background: var(--color-forgetMeNot);
  color: var(--color-bakersChocolate);
`;

export const HeaderInputContainer = styled.div`
  margin-top: auto;
`;

export const ModuleSchemaFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--color-solitude);
  border-radius: 4px;
  position: relative;
  padding: 0.3rem 0.8rem;
`;

export const OperatorColumnStyled = styled(PadBox)<{
  $operator?: string;
  $marginTop?: string;
}>`
  background-color: ${({ $operator = 'and' }) =>
    $operator === 'and'
      ? `var(--color-magnolia);`
      : `var(--color-forgetMeNot);`};
  ${({ $marginTop = '0rem' }) => `margin-top: ${$marginTop};`}

  border-radius: 2rem;
  position: absolute;
  right: -2.5rem;
  top: 1rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PropertyMenuItem = styled(PadBox)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.4rem;
  padding: 0.5rem;
`;
