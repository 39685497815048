import { type UseControllerProps, type UseFormSetValue } from 'react-hook-form';

import VirtualizedRowsFieldArray from './VirtualizedRowsFieldArray';

type DecisionTableRowsProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
};

export default function DecisionTableRows({
  control,
  setValue,
}: DecisionTableRowsProps) {
  return <VirtualizedRowsFieldArray setValue={setValue} control={control} />;
}
