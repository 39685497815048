import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Typography } from 'ui';

import { siteConstantsAtom } from '../../../atom';
import { Header } from '../../../components/Header';
import { PagesBodyContainer } from '../../../components/layouts/PagesBodyContainer';
import { useAxiosVidhanPrivate } from '../../../hooks/useAxiosVidhanPrivate';
import { useGetSiteMeta } from '../../../hooks/useGetSiteMeta';
import { useProfile } from '../../../hooks/useProfile';
import { checkIfThemeEnabled, showErrorToast } from '../../../utils/common';
import { envMap } from '../../../utils/constant';
import { currentWorkspaceDetailAtom } from '../../Workspace/atom';
import { useVerifyWSInviteToken } from '../../Workspace/hooks/useVerifyWSInviteToken';
import { WigetsContainer } from './Home.Styled';
import { Wigdets } from './Widgets';
// eslint-disable-next-line
import noWorkspaceImg from '/assets/konark/home/no_content.png';
// eslint-disable-next-line
import wlImage from '/assets/konark/images/Home.svg';

const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;
export function Home() {
  const [homePageActionData, setHomePageActionData] = useState<any>([]);
  const { siteMeta } = useGetSiteMeta();

  const { axiosVidhanPrivate } = useAxiosVidhanPrivate();

  const [siteConstants] = useAtom(siteConstantsAtom);
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const [profileCallEnabled, setProfileCallEnabled] = useState(true);
  const [userName, setUserName] = useState<string>('');
  const resp = useProfile(profileCallEnabled);
  const pageConstants = siteConstants?.home;
  const { callTokenVerificationAPI, verificationData, isLoading, error } =
    useVerifyWSInviteToken();

  const wsid =
    sessionStorage.getItem('workspaceUUID') ??
    localStorage.getItem('workspaceUUID');

  useEffect(() => {
    if (!_isNil(resp?.data)) {
      setUserName(resp.data?.firstName ?? '');
      void getHomePageJSON(resp.data);
    }
  }, [JSON.stringify(resp.data)]);

  useEffect(() => {
    setProfileCallEnabled(false);
    handleSourceRedirect();
  }, []);

  const handleSourceRedirect = () => {
    const redirectPayloadEncoded = localStorage.getItem('sourceRedirect');

    if (!_isNil(redirectPayloadEncoded)) {
      const decodedPayload = JSON.parse(window.atob(redirectPayloadEncoded));

      // check if pathname is /home then call api.
      if (window.location.pathname === '/home') {
        callTokenVerificationAPI(decodedPayload);
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      // if error is present show error toast and redirect to home page
      // if the user is logged in redirect to home page

      if (!_isEmpty(error)) {
        showErrorToast(error);
        delete window.localStorage.sourceRedirect;
      }
      // if the token verification is successfull then
      // set the invited workspace id in localstorage
      // redirect the user to the homepage and reload the page.

      if (!_isEmpty(verificationData)) {
        if (
          !_isEmpty(verificationData?.data?.data) &&
          !_isNil(verificationData?.data?.data)
        ) {
          window.sessionStorage.setItem(
            'workspaceUUID',
            verificationData.data.data.workspaceUUID
          );
        }

        delete window.localStorage.sourceRedirect;
        window.open(`/home`, '_self');
      }
    }
  }, [isLoading]);

  const getHomePageJSON = async (user: any) => {
    const environment =
      envMap.VITE_ENVIRONMENT === 'development' ? 'staging' : 'production';

    const apiConfig = {
      url: envMap.VITE_API_HOME_PAGE,
      payload: {
        environment,
        isTest: false,
        params: {
          userid: window.localStorage.getItem('userUUID'),
        },
      },
    };

    let data = [];

    if (deploymentType === 'self') {
      const response = await axiosVidhanPrivate.post(
        apiConfig.url,
        apiConfig.payload
      );
      data = response?.data?.data?.additionalOutput ?? [];
    } else {
      const response = await axiosVidhanPrivate.get(
        `${window.location.origin}${envMap.VITE_ASSETS_URL}json/fallback/home/homepage.json`
      );
      data = response.data;
    }

    if (!_isEmpty(data)) {
      setHomePageActionData(data.computedData);
    }
  };

  const isWhiteLabeled =
    checkIfThemeEnabled(
      currentWorkspace?.whiteLabelEnabled,
      envMap.VITE_FULL_WHITELABELLED === 'true'
    ) ?? false;

  return (
    <PagesBodyContainer>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <Header
        content={
          <Typography name="secondary" fontWeight={700}>
            Let&apos;s Get Started
          </Typography>
        }
      />
      <Typography name="heading1" fontWeight={900}>
        {pageConstants?.otherText.welcome} {userName.split(' ')[0]} 🎉
      </Typography>
      {homePageActionData.length > 0 &&
        deploymentType === 'onpremise' &&
        wsid !== '00000000-0000-0000-0000-000000000000' &&
        !isWhiteLabeled && (
          <WigetsContainer>
            <Wigdets homePageActionData={homePageActionData} user={resp.data} />
          </WigetsContainer>
        )}
      {homePageActionData.length > 0 &&
        deploymentType === 'self' &&
        wsid !== '00000000-0000-0000-0000-000000000000' &&
        !isWhiteLabeled && (
          <WigetsContainer>
            <Wigdets homePageActionData={homePageActionData} user={resp.data} />
          </WigetsContainer>
        )}

      {wsid === '00000000-0000-0000-0000-000000000000' && (
        <Stack gutter="1rem">
          <img
            style={{
              margin: '4rem auto',
            }}
            src={noWorkspaceImg}
            alt="no-workspace-image"
          />
          <Inline justify="center">
            <Typography name="heading3">
              {`You don't have an active workspace.`}
            </Typography>
          </Inline>
          <Inline justify="center">
            <Button id="no-workspace-contact-support">Contact Support</Button>
          </Inline>
        </Stack>
      )}

      {isWhiteLabeled && (
        <img
          style={{
            margin: '0 auto',
          }}
          src={wlImage}
          alt="white-labeled-home-image"
        />
      )}
    </PagesBodyContainer>
  );
}
