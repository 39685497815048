import { Inline, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import Cookies from 'js-cookie';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useSearchParams } from 'react-router-dom';
import { Button, Link, Spinner, TextField, Typography } from 'ui';
import { z } from 'zod';

import { SignupFormContainer } from '../../../components/authentication/layout/AuthPagesLayout.styled';
import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import {
  generateCaptchaToken,
  getPublicIP,
  getTimeZone,
} from '../../../utils/common';
import { envMap } from '../../../utils/constant';
import { emailValidationSchema } from '../../../utils/schema';
import { AcceptTCContainer, CustomLinks } from './Signin.styled';
import { useSignin } from './useSignin';

const FormValidationSchema = z.object({
  ...emailValidationSchema,
  password: z.string().min(8, 'Password must contain at least 8 characters'),
});

type SigninFormValues = {
  email: string;
  password: string;
};

export const SigninForm = (props: any) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const { signin, isLoading: isApiLoading } = useSignin({ setIsLoading });
  const showSpinner = isLoading || isApiLoading;

  const { sendEventToGTM } = useSendEventToGTM();

  const { control, handleSubmit } = useForm<SigninFormValues>({
    resolver: zodResolver(FormValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (!_isNil(searchParams.get('sourceRedirect'))) {
      window.localStorage.setItem(
        'sourceRedirect',
        searchParams.get('sourceRedirect') ?? ''
      );
    }
  }, []);

  useEffect(() => {
    sendEventToGTM({
      event: 'page_view',
      page_path: window.location.pathname,
      page_title: 'Signin',
      page_location: window.location.href,
    });

    const source: Record<string, string> = {
      ...JSON.parse(window.localStorage.getItem('source') ?? '{}'),
      ...JSON.parse(Cookies.get('nected_source') ?? '{}'),
    };
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    for (const [key, value] of params.entries()) {
      if (key.includes('utm_')) {
        source[key] = value;
      }
    }

    source.utm_timeZone = getTimeZone();

    window.localStorage.setItem('source', JSON.stringify(source));
    void getPublicIP();
  }, []);

  const onSubmit = ({ email, password }: SigninFormValues) => {
    generateCaptchaToken(signin, { email, password });
  };

  return (
    <SignupFormContainer onSubmit={handleSubmit(onSubmit)}>
      <Stack gutter="1.8rem">
        <Stack gutter=".8rem">
          <Stack gutter="1.6rem">
            <TextField name="email" control={control} label="Email" />

            <TextField
              onClickInputIcon={() => setTogglePassword((isShow) => !isShow)}
              type={togglePassword ? 'text' : 'password'}
              name="password"
              icon={
                togglePassword ? (
                  <AiOutlineEye size={20} />
                ) : (
                  <AiOutlineEyeInvisible size={20} />
                )
              }
              control={control}
              label="Password"
            />
          </Stack>

          <Inline justify="end">
            <Link to="/forgot-password">Forgot Password</Link>
          </Inline>
        </Stack>
        <Button type="submit" size="large" disabled={showSpinner}>
          {showSpinner ? <Spinner size="extraSmall" /> : 'Sign In'}
        </Button>
        {
          // eslint-disable-next-line
          !props.isWhiteLabeled && (
            <Stack gutter="0.5rem">
              <AcceptTCContainer gutter={0}>
                <Typography name="secondary2">
                  By signing in your details, you agree with our
                  <CustomLinks
                    onClick={() => window.open(envMap.VITE_TANDC_URL, '_blank')}
                  >
                    {' '}
                    Terms & Conditions{' '}
                  </CustomLinks>{' '}
                  and
                  <CustomLinks
                    onClick={() => window.open(envMap.VITE_PP_URL, '_blank')}
                  >
                    {' '}
                    Privacy Policy
                  </CustomLinks>
                  .
                </Typography>
              </AcceptTCContainer>
            </Stack>
          )
        }
        {(props.isFormEnabled as boolean) && (
          <Stack gutter="1.6rem">
            <Inline>
              <Typography name="secondarySmall">
                Don&apos;t have an account?
              </Typography>

              <Link to={`/signup${window.location.search}`}>Sign Up</Link>
            </Inline>
          </Stack>
        )}
      </Stack>
    </SignupFormContainer>
  );
};
