import { PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

type TableContainerStyledProp = {
  flexGrow: number;
};

export const TableContainerStyled = styled(PadBox)<TableContainerStyledProp>`
  background-color: var(--color-white);
  overflow-y: auto;
  display: flex;
  flex-grow: ${({ flexGrow }) => flexGrow};

  table {
    border-spacing: 0;
    inline-size: 100%;

    tr {
      display: flex;
    }

    th,
    td {
      padding: 0.5rem;
      border-left: 1px solid var(--color-whiteSmoke);
      border-bottom: 1px solid var(--color-whiteSmoke);
      flex-basis: 70%;
      padding-left: 3.6rem;

      :first-child {
        flex-basis: 30%;
        padding-left: 1.6rem;
      }

      :last-child {
        border-right: 1px solid var(--color-whiteSmoke);
      }
    }

    th {
      font-size: 16px;
      color: var(--color-black);
      text-align: left;
      border-top: 1px solid var(--color-whiteSmoke);
      line-height: 16px;
    }

    td {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 22px;
    }
  }
`;
