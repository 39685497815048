import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { GoArrowLeft } from 'react-icons/go';
import {
  IconButton,
  JsonTableViewHeaderDataType,
  JsonTableViewRowDataType,
  JsonTableViewer,
  getDataTypeNected,
} from 'ui';
import { IconByDataType } from 'ui/src/ConditionPopovers/RulePopover/IconByDataType';

import { getPropertyIfExists } from '../../utils/common';
import {
  BreadCrumbContainer,
  BreadCrumbItem,
  ItemStyle,
  ListContainer,
} from './JsonInTableView.styled';
import { getDetailsForTableView } from './helperFunction';

type JsonInTableViewProps = {
  json: any;
};

type BreadCrumInfoType = {
  name: string;
  dataType: string;
  key: string;
};

export type TableDetailType = {
  header: JsonTableViewHeaderDataType[];
  row: JsonTableViewRowDataType[];
};

export const JsonInTableView = ({ json }: JsonInTableViewProps) => {
  const [breadcrumList, setBreadCrumList] = useState<BreadCrumInfoType[]>([]);
  const [jsonData, setJsonData] = useState<any>({});

  const [tableDetails, setTableDetails] = useState<TableDetailType>();

  useEffect(() => {
    setBreadCrumList([
      {
        name: 'Root',
        dataType: getDataTypeNected(jsonData?.root),
        key: 'root',
      },
      {
        name: 'output',
        dataType: getDataTypeNected(jsonData?.root?.output ?? null),
        key: 'root.output',
      },
    ]);
  }, [JSON.stringify(jsonData)]);

  useEffect(() => {
    setJsonData({
      root: json,
    });
  }, [JSON.stringify(json)]);

  useEffect(() => {
    if (!_isNil(breadcrumList) && !_isEmpty(breadcrumList)) {
      const latestBreadCrumInfo = breadcrumList[breadcrumList.length - 1];
      const value = getPropertyIfExists(jsonData, latestBreadCrumInfo.key);

      const tableDetails: TableDetailType = {
        header: [],
        row: [],
      };
      getDetailsForTableView(value, latestBreadCrumInfo.dataType, tableDetails);
      setTableDetails(tableDetails);
    } else {
      setTableDetails(undefined);
    }
  }, [JSON.stringify(breadcrumList)]);

  const handleCellClick = (
    key: string,
    index: number,
    dataType = 'string',
    cIndex?: number
  ) => {
    if (!_isNil(breadcrumList) && !_isEmpty(breadcrumList)) {
      const lastBreadCrumInfo = breadcrumList[breadcrumList.length - 1];
      let currentKey = lastBreadCrumInfo.key;

      if (lastBreadCrumInfo.dataType === 'list') {
        currentKey +=
          key === 'List' || key === 'Values'
            ? _isNil(cIndex)
              ? `[${index}]`
              : key === 'Values'
              ? `[${cIndex}]`
              : `[${index}][${cIndex}]`
            : _isNil(cIndex)
            ? `[${index}].${key}`
            : `[${index}].${key}[${cIndex}]`;
      } else {
        currentKey += `.${key}`;
      }

      setBreadCrumList([
        ...breadcrumList,
        {
          name: key,
          dataType,
          key: currentKey,
        },
      ]);
    }
  };

  const handleBack = () => {
    setBreadCrumList(breadcrumList.slice(0, breadcrumList.length - 1));
  };

  const handleBreadCrumbClick = (key: string) => {
    const index = breadcrumList.findIndex((item) => item.key === key);

    if (index !== -1) {
      setBreadCrumList(breadcrumList.slice(0, index + 1));
    }
  };

  function getIndexFromKey(obj: BreadCrumInfoType) {
    const { key, name } = obj;

    if (name === 'List' || name === 'Values') {
      // Match the pattern before the name
      const lastDotIndex = key.lastIndexOf('.');

      if (lastDotIndex === -1) {
        return []; // No dot found, return empty array
      }

      // Substring after the last dot
      const substringAfterDot = key.substring(lastDotIndex + 1);

      // Use regex to find all indices in square brackets
      const indexes = [];
      const regex = /\[(\d+)\]/g;
      let match;

      // Loop through all matches
      while ((match = regex.exec(substringAfterDot)) !== null) {
        indexes.push(parseInt(match[1], 10)); // Convert to integer and add to array
      }

      return indexes;
    } else {
      // Match the pattern before the name
      const regex = new RegExp(`\\[(\\d+)\\](?=\\.${name})`);
      const match = key.match(regex);

      // If a match is found, return the index, otherwise return null
      return match != null ? [parseInt(match[1], 10)] : null;
    }
  }

  if (_isNil(breadcrumList) || _isEmpty(breadcrumList)) {
    return null;
  }

  return (
    <Stack
      gutter={0}
      style={{
        minWidth: 'fit-content',
      }}
    >
      <BreadCrumbContainer align="center" gutter="2rem">
        <IconButton disabled={breadcrumList.length === 1} onClick={handleBack}>
          <GoArrowLeft
            size={20}
            color={
              breadcrumList.length === 1
                ? 'var(--color-darkGray)'
                : 'var(--color-primary1)'
            }
          />
        </IconButton>
        <ListContainer align="center" gutter="1rem">
          {breadcrumList.map((currentBreadcrumb, index) => {
            const listIndex = getIndexFromKey(currentBreadcrumb);

            return (
              <Inline key={currentBreadcrumb.key} align="center" gutter="1rem">
                <BreadCrumbItem
                  onClick={() => handleBreadCrumbClick(currentBreadcrumb.key)}
                >
                  {!_isNil(listIndex) && (
                    <ItemStyle fontWeight={700}>{`# ${listIndex
                      .map((val) => val + 1)
                      .join('.')}`}</ItemStyle>
                  )}
                  <Inline align="center" gutter="0.5rem">
                    <IconByDataType
                      dataType={currentBreadcrumb.dataType}
                      color="var(--color-paleDodgerBlue)"
                    />
                    {!['List', 'Values'].includes(currentBreadcrumb.name) ? (
                      <ItemStyle>{currentBreadcrumb.name}</ItemStyle>
                    ) : (
                      <ItemStyle>{currentBreadcrumb.dataType}</ItemStyle>
                    )}
                  </Inline>
                </BreadCrumbItem>

                {index !== breadcrumList.length - 1 && (
                  <FiChevronRight size={24} />
                )}
              </Inline>
            );
          })}
        </ListContainer>
      </BreadCrumbContainer>

      <div>
        <JsonTableViewer
          header={tableDetails?.header ?? []}
          row={tableDetails?.row ?? []}
          onClick={handleCellClick}
        />
      </div>
    </Stack>
  );
};
