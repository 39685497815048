import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import AceEditor from 'react-ace';
import { JsonHeroTreeViewer } from 'ui';

import { JsonInTableView } from '../../../../../../components/JsonInTableView/JsonInTableView';
import { ExecutionView } from '../../../../../../components/jsonOutputField/components/ExecutionView';
import {
  JsonContainer,
  JsonTreeContainer,
  OutputContainer,
} from './RestApiTest.styled';

type RestApiNodeTestProps = {
  output?: Record<string, any>;
};
export function RestApiNodeTest({
  output = {
    _comment: 'Please Test the node first',
  },
}: RestApiNodeTestProps) {
  return (
    <PadBox padding="2rem">
      <OutputContainer padding="1rem">
        <Stack gutter="1rem">
          <ExecutionView>
            <JsonContainer>
              <AceEditor
                className="json-result-readonly"
                mode="json"
                theme="chrome"
                width="100%"
                fontSize={12}
                showPrintMargin={false}
                highlightActiveLine={true}
                showGutter={true}
                setOptions={{
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                readOnly
                value={JSON.stringify(output, null, 2)}
              />
            </JsonContainer>

            <JsonTreeContainer>
              <JsonHeroTreeViewer json={output} />
            </JsonTreeContainer>

            <JsonTreeContainer>
              <JsonInTableView json={{ output: output?.output ?? {} }} />
            </JsonTreeContainer>
          </ExecutionView>
        </Stack>
      </OutputContainer>
    </PadBox>
  );
}
