import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import { Typography } from 'ui';

export const OutputHeaderContainer = styled(Inline)<{
  $hasBottomBorder: boolean;
}>`
  padding: 1rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-lightGray8);

  ${({ $hasBottomBorder }) => !$hasBottomBorder && 'border-bottom: none;'}

  .dataTypetype-style {
    text-transform: capitalize;
  }
`;

export const BreadCrumbContainer = styled(Inline)`
  padding: 1rem;
  background-color: var(--color-white);
  width: fit-content;
  /* border: 1px solid var(--color-lightGray8); */
  border-bottom: none;
`;

export const ListContainer = styled(Inline)`
  flex-wrap: wrap;
`;

export const BreadCrumbItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.4rem 0.8rem;
  border-radius: 0.5rem;
  background-color: var(--color-primary2);
  cursor: pointer;
`;

export const ItemStyle = styled(Typography)`
  color: var(--color-primary1);
`;
