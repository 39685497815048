import { Inline, Stack } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import type {
  ChangeEvent,
  HTMLInputTypeAttribute,
  ReactNode,
  Ref,
} from 'react';
import { forwardRef } from 'react';
import { BiExpandAlt } from 'react-icons/bi';
import { GoAlert } from 'react-icons/go';

import { useLayer } from '../../Layer';
import { FontWeight, Typography } from '../../Typography';
import { TextInputInModal } from '../TextInputInModal';
import {
  ExpandIconContainer,
  InputAndIconStyled,
  InputIconContainerStyled,
  InputStartIconContainerStyled,
  InputStyled,
} from './TextInput.styled';

export type InputSize = 'extraSmall' | 'small' | 'medium' | 'self';

export type TextInputProps = {
  icon?: ReactNode;
  startIcon?: ReactNode;
  hasError?: boolean;
  id?: string;
  name?: string;
  onClickInputIcon?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: any) => void;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  label?: string;
  showError?: boolean;
  size?: InputSize;
  disabled?: boolean;
  readOnly?: boolean;
  showErrorIcon?: boolean;
  isSmallInput?: boolean;
  showOutline?: boolean;
  labelFontWeight?: FontWeight;
  showExpandIcon?: boolean;
  isIntegerOnly?: boolean;
  style?: Record<string,any>;
};

type InputWidths = {
  [key in InputSize]: number;
};

const inputSize: InputWidths = {
  extraSmall: 5,
  small: 10,
  medium: 15,
  self: 20,
};

export const TextInput = forwardRef(
  (
    {
      style,
      placeholder,
      value,
      type = 'text',
      name,
      id,
      startIcon,
      icon,
      hasError = false,
      onClickInputIcon,
      onChange,
      onBlur,
      label,
      showError = true,
      size = 'self',
      disabled = false,
      readOnly = false,
      showErrorIcon = true,
      isSmallInput = false,
      showOutline = true,
      labelFontWeight,
      showExpandIcon = false,
      isIntegerOnly = false,
    }: TextInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const hasIcon = !_isNil(icon);
    const hasStartIcon = !_isNil(startIcon);

    const handleChange = (e: any) => {
      const inputValue = e.target.value;

      if (/^\d*$/.test(inputValue) && isIntegerOnly && !_isNil(onChange)) {
        onChange(e);
      } else if (!_isNil(onChange) && !isIntegerOnly) {
        onChange(e);
      }
    };

    const { openWithProps: openTextInputModal } = useLayer(
      <TextInputInModal value={value} />
    );

    return (
      <Stack gutter=".8rem">
        {!_isNil(label) && (
          <label>
            <Typography fontWeight={labelFontWeight}>{label}</Typography>
          </label>
        )}
        <Inline align="center" gutter="1.1rem" stretch="start">
          <InputAndIconStyled align="center" stretch="start">
            {hasStartIcon && (
              <InputStartIconContainerStyled onClick={onClickInputIcon}>
                {startIcon}
              </InputStartIconContainerStyled>
            )}

            <InputStyled
              style={style}
              hasIcon={hasIcon}
              hasStartIcon={hasStartIcon}
              showExpandIcon={showExpandIcon}
              id={id}
              type={type}
              value={value}
              onChange={handleChange}
              onBlur={onBlur}
              placeholder={placeholder}
              hasError={hasError}
              name={name}
              ref={ref}
              size={inputSize[size]}
              disabled={disabled}
              readOnly={readOnly}
              isSmallInput={isSmallInput}
              showOutline={showOutline}
            />

            {hasIcon && (
              <InputIconContainerStyled onClick={onClickInputIcon}>
                {icon}
              </InputIconContainerStyled>
            )}

            {showExpandIcon && (
              <ExpandIconContainer
                onClick={() => {
                  openTextInputModal({
                    onChange,
                    value,
                    disabled: disabled || readOnly,
                  });
                }}
              >
                <BiExpandAlt size={12} />
              </ExpandIconContainer>
            )}
          </InputAndIconStyled>

          {hasError && showError && showErrorIcon && (
            <span>
              <GoAlert fill="var(--color-fireEngineRed)" size={20} />
            </span>
          )}
        </Inline>
      </Stack>
    );
  }
);

TextInput.displayName = 'TextInput';
