/* eslint-disable */
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState, useTransition } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useFieldArray, useWatch } from 'react-hook-form';
import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa';
import { FiPlusCircle } from 'react-icons/fi';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List as ListType,
} from 'react-virtualized';
import { CheckboxField, IconButton, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { siteConstantsAtom } from '../../../../../atom';
import { AddButton } from '../../../../../components/AddButton';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { generateUid, getTooltipText } from '../../../../../utils/common';
import type { sendEventToGTMType } from '../../../types';
import {
  getDefaultValueByDataType,
  getGroupNode,
  getRequiredKey,
} from '../../../utils/common';
import { getIfHasParentGroup } from '../../../utils/decisionTable';
import {
  decisionTableErrorConfig,
  decisionTableNodeIdAtom,
  decisionTableNodesAtom,
} from '../DecisionTable';
import { DecisionTableRowRhsNode, PropertiesNodeStructure } from '../models';
import { DecisionTableRow } from '../types';
import { ConditionFieldArray } from './ConditionFieldArray';
import { RowActions } from './RowActions';
import { RowResultFieldArray } from './RowResultFieldArray';
import {
  AddRow,
  AddRowStyled,
  RowActionsContainer,
  RowNumContainer,
} from './RowsFieldArray.styled';

type RowsFieldArrayProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
};

const OVERSCAN_ROW_COUNT = 8;

export default function VirtualizedRowsFieldArray({
  control,
  setValue,
}: RowsFieldArrayProps) {
  const [totalHeight, setTotalHeight] = useState(50);
  const listRef = useRef<ListType>(null);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const { sendEventToGTM } = useSendEventToGTM();
  const [ruleId] = useAtom(decisionTableNodeIdAtom);
  const [nodes, setNodes] = useAtom(decisionTableNodesAtom);
  const [visibleRows, setVisibleRows] = useState({
    startIndex: 0,
    stopIndex: 0,
  });
  const properties: PropertiesNodeStructure[] = useWatch({
    control,
    name: 'properties',
  });
  const results = useWatch({ control, name: 'results' });
  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });
  const rows: Array<Record<string, DecisionTableRow>> = useWatch({
    control,
    name: 'rows',
  });
  const { fields } = useFieldArray({
    name: 'rows',
    control,
  });
  const [, startTransition] = useTransition();

  // Create a cache instance with key mapping
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 30,
      defaultHeight: 30,
      keyMapper: (rowIndex: number) => {
        const field = fields[rowIndex];
        return field ? `${rowIndex}-${field.id}` : rowIndex.toString();
      },
    })
  );

  const calculateGridRowHeight = (): void => {
    setTimeout(() => {
      const grid = document.querySelector('.ReactVirtualized__Grid');
      if (!grid) return;

      const rows = grid.querySelectorAll(
        '.ReactVirtualized__Grid__innerScrollContainer > div'
      );
      let total = 0;

      Array.from(rows).forEach((row) => {
        const style = window.getComputedStyle(row);
        const height = parseFloat(style.height) || 0;
        total += height;
      });

      setTotalHeight(total);
    }, 100);
  };
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [errorConfigData] = useAtom(decisionTableErrorConfig);

  const recomputeAllRowHeights = useCallback(
    (scrollContainer: any, scrollTop: any, forceFullRecalc = false) => {
      if (forceFullRecalc) {
        cache.current.clearAll();
        listRef?.current?.recomputeRowHeights(0);
      } else {
        for (let i = visibleRows.startIndex; i <= visibleRows.stopIndex; i++) {
          cache.current.clear(i, 0);
        }
        listRef?.current?.recomputeRowHeights(visibleRows.startIndex);
      }

      requestAnimationFrame(() => {
        if (scrollContainer && typeof scrollTop === 'number') {
          scrollContainer.scrollTop = scrollTop;
        }
        calculateGridRowHeight();
      });
    },
    [visibleRows]
  );

  const onRowsRendered = useCallback(
    ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
      setVisibleRows({ startIndex, stopIndex });
    },
    []
  );

  useEffect(() => {
    const scrollContainer = document.querySelector('.ReactVirtualized__Grid');
    const scrollTop = scrollContainer?.scrollTop ?? 0;
    recomputeAllRowHeights(scrollContainer, scrollTop);
  }, [JSON.stringify(rows)]);

  useEffect(() => {
    calculateGridRowHeight();
  }, []);

  const rowRenderer = useCallback(
    ({ index, key, parent, style }: any) => {
      const field = fields[index];
      if (!field) return null;

      const rowKey = getRequiredKey(field, ['id']);
      const isErrorInRow = errorConfigData[index]?.length > 0 || false;

      return (
        <CellMeasurer
          cache={cache.current}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
        >
          {({ measure, registerChild }) => (
            <div ref={registerChild} style={style} onLoad={measure}>
              <Inline gutter={0} align="stretch">
                <RowActionsContainer align="center" gutter={12}>
                  <CheckboxField
                    name={`rows.${index}.${rowKey}.isEnabled`}
                    useId={`rows.${index}.${rowKey}.isEnabled`}
                    control={control}
                    appearance="switch"
                    disabled={isRuleReadOnly}
                  />
                  <IconButton
                    disabled={isRuleReadOnly}
                    onClick={() => duplicateRowHandler(index)}
                  >
                    ⧉
                  </IconButton>
                  <RowActions
                    control={control}
                    setValue={setValue}
                    index={index}
                    rowKey={rowKey}
                    addRowAtIndex={addRowHandler}
                    duplicateRowHandler={duplicateRowHandler}
                    isLast={fields.length === 1}
                    handleSendEventToGTM={handleSendEventToGTM}
                  />
                </RowActionsContainer>

                <RowNumContainer
                  justify="center"
                  align="center"
                  $isError={isErrorInRow}
                >
                  <Typography>{index + 1}</Typography>
                </RowNumContainer>

                <ConditionFieldArray
                  index={index}
                  setValue={setValue}
                  control={control}
                  rowKey={rowKey}
                  handleSendEventToGTM={handleSendEventToGTM}
                  isError={isErrorInRow}
                />
                <RowResultFieldArray
                  index={index}
                  setValue={setValue}
                  control={control}
                  rowKey={rowKey}
                  handleSendEventToGTM={handleSendEventToGTM}
                  isError={isErrorInRow}
                />
              </Inline>
            </div>
          )}
        </CellMeasurer>
      );
    },
    [fields, control, isRuleReadOnly, errorConfigData]
  );

  const handleSendEventToGTM = ({
    action = '',
    element = '',
    actionName = '',
  }: sendEventToGTMType) => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'decisionTable',
      nec_source: '',
      action,
      element,
      action_name: actionName,
    });
  };

  const addRowHandler = useCallback(
    (atIndex?: number) => {
      if (isRuleReadOnly) return;

      handleSendEventToGTM({
        action: 'add',
        element: 'row',
        actionName: '',
      });

      const groupId = generateUid('rule_');
      const childNodes: string[] = [];
      const conditionList: any[] = [];
      const resultList: any[] = [];
      let previousGroup: string | null = null;

      const createConditionNode = (
        propertyKey: string,
        property: any,
        parent: string
      ) => ({
        nodeType: ['jsCondition', 'excelCondition'].includes(
          property[propertyKey].dataType
        )
          ? property[propertyKey].dataType
          : 'condition',
        parent,
        operator: ['jsCondition', 'excelCondition'].includes(
          property[propertyKey].dataType
        )
          ? ''
          : 'any',
        siblingIndex: 1,
        leftNode: [propertyKey],
        rightNode: [],
        dataType: property[propertyKey]?.dataType,
        name: 'Any',
        query: ['jsCondition'].includes(property[propertyKey].dataType)
          ? `${jsCommentText}\n1===1`
          : '1===1',
      });

      const jsCommentText = getTooltipText(
        siteConstants,
        'rules',
        'formulaInCondition',
        'otherText'
      );
      const nodeUpdates: Record<string, any> = {};

      properties?.forEach((property, index: number) => {
        const conditionID = generateUid('rule_');
        const propertyKey = getRequiredKey(property, ['id']);

        const ruleRow = {
          [conditionID]: {
            value: 'any',
            type: 'generic',
            leftOperands: 1,
            rightOperands: 0,
          },
          rhs: [],
        };

        if (
          ['jsCondition', 'excelCondition'].includes(
            property[propertyKey].dataType
          )
        ) {
          ruleRow[conditionID] = {
            ...ruleRow[conditionID],
            value:
              property[propertyKey].dataType === 'jsCondition'
                ? `${jsCommentText}\n1===1`
                : '1===1',
            type: property[propertyKey].dataType,
            // name: 'Any',
            // query: '',
          };
        }

        conditionList.push(ruleRow);

        const doesHaveParent = getIfHasParentGroup(rows, nodes, index);
        const doesHaveParentSib =
          index > 0 ? getIfHasParentGroup(rows, nodes, index - 1) : null;

        if (!doesHaveParent.hasParent) {
          childNodes.push(conditionID);
          nodeUpdates[conditionID] = createConditionNode(
            propertyKey,
            property,
            groupId
          );
        } else if (doesHaveParentSib?.group === doesHaveParent.group) {
          nodeUpdates[conditionID] = createConditionNode(
            propertyKey,
            property,
            previousGroup ?? ''
          );
          if (previousGroup) {
            nodeUpdates[previousGroup] = {
              ...(nodes[previousGroup] || {}),
              children: [
                ...(nodes[previousGroup]?.children || []),
                conditionID,
              ],
            };
          }
        } else {
          const newGroupId = generateUid('rule_');
          childNodes.push(newGroupId);
          previousGroup = newGroupId;

          nodeUpdates[conditionID] = createConditionNode(
            propertyKey,
            property,
            newGroupId
          );
          nodeUpdates[newGroupId] = getGroupNode(
            groupId,
            [conditionID],
            doesHaveParent.operator
          );
        }
      });

      results?.forEach((result: any) => {
        const resultKey = getRequiredKey(result, ['id']);
        const outputKey = generateUid('output_');
        const dataType = result[resultKey].dataType ?? 'string';

        resultList.push({
          [outputKey]: {
            dataType,
            value:
              dataType === 'boolean'
                ? 'false'
                : getDefaultValueByDataType(result[resultKey].dataType),
          },
        });
      });

      const firstField = getRequiredKey(fields[0], ['id']);
      nodeUpdates[groupId] = {
        nodeType: 'group',
        parent: '',
        operator: nodes[firstField]?.operator ?? 'and',
        siblingIndex: 1,
        children: childNodes,
      };

      const rowItem = {
        [groupId]: {
          isEnabled: true,
          condition: conditionList,
          ruleResult: resultList,
        },
      };

      startTransition(() => {
        if (typeof atIndex === 'number') {
          const newRows = [...rows];
          newRows.splice(atIndex, 0, rowItem);
          setValue('rows', newRows);
        } else {
          setValue('rows', [...rows, rowItem]);
        }
        setNodes((prev) => ({
          ...prev,
          ...nodeUpdates,
        }));

        setTimeout(() => {
          requestAnimationFrame(() => {
            listRef.current?.scrollToRow(fields.length + 1);
          });
        }, 300);
      });
    },
    [isRuleReadOnly, rows, nodes, properties, results, fields]
  );

  const duplicateRowHandler = (rowIndex: number) => {
    const groupId = generateUid('rule_');
    const childNodes: string[] = [];
    const conditionList: any[] = [];
    const resultList: any[] = [];

    const newRows = [...rows];
    const rowKey = getRequiredKey(rows[rowIndex], ['id']);

    let newGroupId: string | null = null;

    rows[rowIndex][rowKey].condition.forEach((condition, cIndex) => {
      const conditionKey = getRequiredKey(condition, ['id', 'rhs']);

      const doesHaveParent = getIfHasParentGroup(rows, nodes, cIndex);
      const doesHaveParentSib = getIfHasParentGroup(rows, nodes, cIndex - 1);

      const newConditionKey = generateUid('rule_');

      if (doesHaveParent.hasParent) {
        if (doesHaveParent.group !== doesHaveParentSib.group) {
          newGroupId = generateUid('rule_');
          setNodes((prev) => ({
            ...prev,
            [newGroupId ?? '']: getGroupNode(
              groupId,
              [...(prev[newGroupId ?? '']?.children ?? []), newConditionKey],
              doesHaveParent.operator
            ),
          }));

          childNodes.push(newGroupId);
        } else if (doesHaveParent.group === doesHaveParentSib.group) {
          setNodes((prev) => ({
            ...prev,
            [newGroupId ?? '']: {
              ...prev[newGroupId ?? ''],
              children: [
                ...(prev[newGroupId ?? '']?.children ?? []),
                newConditionKey,
              ],
            },
          }));
        }
      } else {
        childNodes.push(newConditionKey);
      }

      const conditionNode = nodes[conditionKey];

      const rhsIdList: string[] = [];
      const rhsNodeList: DecisionTableRowRhsNode = [];

      condition.rhs.forEach((rhsNode) => {
        const rhsKey = getRequiredKey(rhsNode, ['id']);
        const newRhsNodeKey = generateUid('rule_');

        rhsIdList.push(newRhsNodeKey);

        setNodes((prev) => ({
          ...prev,
          [newRhsNodeKey]: { ...prev[rhsKey], parent: newConditionKey },
        }));

        rhsNodeList.push({
          [newRhsNodeKey]: rhsNode[rhsKey],
        });
      });

      setNodes((prev) => ({
        ...prev,
        [newConditionKey]: {
          ...conditionNode,
          parent: doesHaveParent.hasParent ? newGroupId ?? '' : groupId,
          rightNode: rhsIdList,
        },
      }));

      const newConditionNode = {
        [newConditionKey]: {
          ...condition[conditionKey],
        },
        rhs: rhsNodeList,
      };

      conditionList.push(newConditionNode);
    });

    rows[rowIndex][rowKey].ruleResult.forEach((result) => {
      const resultKey = getRequiredKey(result, ['id']);
      const newResultKey = generateUid('output_');

      resultList.push({
        [newResultKey]: result[resultKey],
      });
    });

    const rowToBeCopied = {
      [groupId]: {
        condition: conditionList,
        isEnabled: rows[rowIndex][rowKey].isEnabled,
        ruleResult: resultList,
      },
    };

    setNodes((prev) => ({
      ...prev,
      [groupId]: {
        ...prev[rowKey],
        children: childNodes,
      },
    }));
    newRows.splice(rowIndex + 1, 0, rowToBeCopied);

    setValue('rows', [...newRows]);
  };

  return (
    <>
      <div
        style={{
          height: '100%',
          minHeight: totalHeight > 400 ? '400px' : `${totalHeight}px`,
        }}
      >
        <AutoSizer>
          {({ width, height }) => (
            <ListType
              width={width}
              height={height}
              rowCount={fields.length}
              deferredMeasurementCache={cache.current}
              rowHeight={cache.current.rowHeight}
              rowRenderer={rowRenderer}
              overscanRowCount={OVERSCAN_ROW_COUNT}
              ref={listRef}
              scrollToAlignment="end"
              style={{ outline: 'none' }}
              onRowsRendered={onRowsRendered}
            />
          )}
        </AutoSizer>
      </div>

      <Inline gutter={0}>
        <RowActionsContainer />
        <AddRow padding={8}>
          <IconButton onClick={() => addRowHandler()}>
            <AddRowStyled $disabled={isRuleReadOnly} align="center" gutter={5}>
              {isRuleReadOnly ? (
                <FiPlusCircle color="var(--color-darkGray)" />
              ) : (
                <AddButton />
              )}
              <Typography>Add Row</Typography>
            </AddRowStyled>
          </IconButton>
          <IconButton
            onClick={() => {
              requestAnimationFrame(() => {
                listRef.current?.scrollToRow(0);
              });
            }}
          >
            <AddRowStyled align="center" gutter={5}>
              <FaArrowCircleUp />
              <Typography>Top</Typography>
            </AddRowStyled>
          </IconButton>
          <IconButton
            onClick={() => {
              requestAnimationFrame(() => {
                listRef.current?.scrollToRow(fields.length);
              });
            }}
          >
            <AddRowStyled align="center" gutter={5}>
              <FaArrowCircleDown />
              <Typography>Bottom</Typography>
            </AddRowStyled>
          </IconButton>
        </AddRow>
      </Inline>
    </>
  );
}
