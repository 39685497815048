import styled from 'styled-components';

import { Typography } from '../Typography';

export const StyledTable = styled.table`
  width: fit-content;
  margin: 1rem;
  border-collapse: collapse;
`;
export const StyledTh = styled.th`
  background-color: var(--color-white);
  border: 1px solid var(--color-lightGray8);
  padding: 0.5rem;

  .name-text-style {
    text-align: left;
  }
`;

export const StyledTr = styled.tr`
  background-color: var(--color-white);
`;

export const StyledTd = styled.td`
  border: 1px solid var(--color-lightGray8);
  padding: 1rem;
  word-break: break-word;
  text-align: left;
  vertical-align: top;
`;

export const LinkText = styled(Typography)`
  text-decoration: underline;
  color: var(--color-primary1);
  cursor: pointer;
`;

export const TypeText = styled(Typography)`
  text-transform: capitalize;
`;

export const LinkTextV2 = styled(Typography)<{ $isTextClip?: boolean }>`
  ${({ $isTextClip = false }) =>
    $isTextClip &&
    `text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;`}

  .normal-text {
    display: inline-block;
  }
  .link {
    display: inline-block;
    text-decoration: underline;
    color: var(--color-primary1);
    cursor: pointer;
  }

  .comma-style-text {
    display: inline-block;
    color: var(--color-black);
  }
`;
