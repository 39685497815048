import * as Comlink from 'comlink';
import { useEffect, useMemo } from 'react';
import { DTTableTransform } from './DTTableTransform';

const makeWorkerApiAndCleanup = () => {
  const worker = new Worker(
    new URL('./DTTableTransform.ts', import.meta.url),
    {
      name: 'dt-calculations',
      type: 'module',
    }
  );
  
  const workerApi = Comlink.wrap<typeof DTTableTransform>(worker);
  
  const cleanup = () => {
    workerApi[Comlink.releaseProxy]();
    worker.terminate();
  };

  return { workerApi, cleanup };
};

export const useTableTransformWorker = () => {
  const workerApiAndCleanup = useMemo(() => makeWorkerApiAndCleanup(), []);

  useEffect(() => {
    return () => {
      workerApiAndCleanup.cleanup();
    };
  }, [workerApiAndCleanup]);

  return workerApiAndCleanup;
};