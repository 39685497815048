import { atom } from 'jotai';

import {
  type ApprovalInfoType,
  type PanelType,
  VersionInfoType,
  VersionMappingInfoType,
} from '../../../../types';
import type { DatasetDetailsInRuleType } from '../../types';

const activePanel = localStorage.getItem("nec_hide_side_panel") === "true" ? undefined : 'settings'
export const activePanelAtom = atom<PanelType | undefined>(activePanel);
export const isRuleLiveAtom = atom<boolean>(false);

export const approvalInfoRuleAtom = atom<ApprovalInfoType | undefined>(
  undefined
);

export const datasetDetailsInRuleAtom = atom<
  DatasetDetailsInRuleType | undefined
>(undefined);

export const versionInfoRuleAtom = atom<VersionInfoType | undefined>(undefined);
export const vcListTabIndexRuleAtom = atom<number>(0);

export const cronInfoAtom = atom<Record<string, any> | undefined>(undefined);

export const errorInRuleAtom = atom<Record<string, boolean>>({
  action: false,
  condition: false,
  outputData: false,
  additionalData: false,
});

export const hasConnectorErrorInCustomAttrSheetAtom = atom<
  Record<string, boolean>
>({
  dataset: false,
  restAPI: false,
});

export const versionMappingInfoAtom = atom<
  VersionMappingInfoType[] | undefined
>(undefined);

export const assetsInDraftMapAtom = atom<Record<string, boolean> | undefined>(
  undefined
);
