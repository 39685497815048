import { Inline } from '@bedrock-layout/inline';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { getDataTypeNected } from '../ConditionPopovers';
import { IconByDataType } from '../ConditionPopovers/RulePopover/IconByDataType';
import { PopoverPanel } from '../Popover';
import { Typography } from '../Typography';
import {
  LinkText,
  LinkTextV2,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledTr,
  TypeText,
} from './JsonTableViewer.styled';

export type JsonTableViewHeaderDataType = {
  name: string;
  dataType?: string;
};

export type JsonTableViewRowDataType = Record<string, any>;

export type JsonTableViewerProps = {
  header: JsonTableViewHeaderDataType[];
  row: JsonTableViewRowDataType[];
  onClick: (
    key: string,
    index: number,
    dataType?: string,
    cIndex?: number
  ) => void;
};

export function JsonTableViewer({
  header,
  row,
  onClick,
}: JsonTableViewerProps) {
  if (_isNil(header) || _isEmpty(header)) {
    return (
      <Inline justify="center" style={{ marginTop: '1rem' }}>
        <Typography>No Data</Typography>
      </Inline>
    );
  }

  const hasHeader =
    header.filter((obj) => !['List', 'Values'].includes(obj.name)).length > 0;

  return (
    <StyledTable>
      <thead>
        <tr>
          {hasHeader && <StyledTh>S.No.</StyledTh>}
          {header
            .filter((obj) => !['List', 'Values'].includes(obj.name))
            .map((obj, index) => (
              <StyledTh key={`${obj.name}_${index}`}>
                <Inline align="end" gutter={'1.6rem'}>
                  <Inline align="center" gutter={'0.8rem'}>
                    <span>
                      <IconByDataType
                        dataType={obj.dataType ?? 'string'}
                        color="var(--color-paleDodgerBlue)"
                      />
                    </span>
                    <Typography className="name-text-style" fontWeight={700}>
                      {obj.name}
                    </Typography>
                  </Inline>
                  <TypeText name="paragraphSmall">
                    {obj.dataType ?? 'string'}
                  </TypeText>
                </Inline>
              </StyledTh>
            ))}
        </tr>
      </thead>
      <tbody>
        {row.map((obj, index) => (
          <StyledTr key={index}>
            <StyledTd key={index}>{index + 1}</StyledTd>
            {header.map((headerObj, cIndex) => {
              const { name, dataType = 'string' } = headerObj;

              if (!(name in obj)) {
                return <StyledTd key={`${name}_${cIndex}}`}>-</StyledTd>;
              }

              const value = obj[name];

              if (
                dataType === 'json' &&
                getDataTypeNected(obj[name]) === 'json'
              ) {
                return (
                  <StyledTd
                    key={`${name}_${cIndex}}`}
                    onClick={() => onClick(name, index, dataType)}
                  >
                    <LinkText>{`Json {}`}</LinkText>
                  </StyledTd>
                );
              }

              if (dataType === 'list' && getDataTypeNected(value) === 'list') {
                const isListOfPrimitives: boolean = value.every(
                  (item: any) => typeof item !== 'object'
                );

                const isListOfJson: boolean = value.every(
                  (item: any) =>
                    typeof item === 'object' && !Array.isArray(item)
                );

                const isListOfList: boolean = value.every((item: any) =>
                  Array.isArray(item)
                );

                const hetrogenousComponent = (
                  <>
                    {value.map((item: any, cIndex: number) => {
                      const isPrimitive = typeof item !== 'object';
                      const isList = Array.isArray(item);

                      const isLast = value.length === cIndex + 1;

                      if (isPrimitive) {
                        return (
                          <div key={cIndex} className="normal-text">
                            {`${item as string}${isLast ? '' : ', '}`}
                          </div>
                        );
                      }

                      if (isList) {
                        return (
                          <div
                            key={cIndex}
                            onClick={() =>
                              onClick(
                                name,
                                index,
                                getDataTypeNected(item),
                                cIndex
                              )
                            }
                            className="link"
                          >
                            {`List [${item.length} items]`}
                            <div className="comma-style-text">
                              {isLast ? '' : ', '}
                            </div>
                          </div>
                        );
                      }

                      return (
                        <div
                          key={cIndex}
                          className="link"
                          onClick={() =>
                            onClick(
                              name,
                              index,
                              getDataTypeNected(item),
                              cIndex
                            )
                          }
                        >
                          {`Json {}`}
                          <div className="comma-style-text">
                            {isLast ? '' : ', '}
                          </div>
                        </div>
                      );
                    })}
                  </>
                );

                return (
                  <StyledTd key={`${name}_${cIndex}}`}>
                    {(isListOfPrimitives || isListOfList || isListOfJson) && (
                      <div onClick={() => onClick(name, index, dataType)}>
                        <LinkText>{`List [${
                          value.length as string
                        } items]`}</LinkText>
                      </div>
                    )}
                    {!isListOfPrimitives && !isListOfList && !isListOfJson && (
                      <PopoverPanel
                        launcher={
                          <div>
                            <LinkTextV2 $isTextClip={true}>
                              {hetrogenousComponent}
                            </LinkTextV2>
                          </div>
                        }
                        arrow
                        padding={0}
                        placement={'bottom-end'}
                      >
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{ padding: '1rem 2rem' }}
                        >
                          <LinkTextV2>{hetrogenousComponent}</LinkTextV2>
                        </div>
                      </PopoverPanel>
                    )}
                  </StyledTd>
                );
              }

              return (
                <StyledTd key={`${name}_${cIndex}}`}>
                  {`${obj[name] as string}`}
                </StyledTd>
              );
            })}
          </StyledTr>
        ))}
      </tbody>
    </StyledTable>
  );
}
