import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import type { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import _capitalize from 'lodash/capitalize';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { Fragment, memo, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { BiChevronDown } from 'react-icons/bi';
import { CiSearch } from 'react-icons/ci';
import {
  Button,
  HorizontalLine,
  Pagination,
  TextButton,
  TextField,
  Typography,
  useLayer,
} from 'ui';

import { axiosPrivate } from '../../../../api/axios';
import { subscriptionPlanAtom } from '../../../../atom';
import { ProfileIconContainer } from '../../../../components/Header/components/profile/ProfileIcon.styled';
import { getUserState } from '../../../../hooks/getUserState';
import type { SubscriptionPlanType, TimerId } from '../../../../types';
import {
  isOnboardingCompleted,
  showErrorToast,
} from '../../../../utils/common';
import { inviteUser } from '../../../../utils/constant';
import type { CustomAxiosError } from '../../../../utils/response/types';
import { updateWidgetState } from '../../../Home/components/sub-components/UpdateWidgetState';
import { useRemoveMemberFromAWorkspace } from '../../hooks/restApi/useRemoveMemberFromAWorkspace';
import { useUpdateAMemberRole } from '../../hooks/restApi/useUpdateAMemberRole';
import { getUserAvatar, getUserName } from '../../utils';
import { InlineChildrenContainer } from '../common/InlineChildrenContainer';
import type { Member, Role, RoleDetails, SearchQueryParams } from '../types';
import { FilterRoleUi } from './FilterRoleUi';
import { InviteModal } from './InviteModal/InviteModal';
import { RoleList } from './RoleList';
import {
  MemberContainer,
  MemberListContainer,
  MemberStatus,
  PaginationContainer,
  TextFieldContainer,
} from './WorkspaceMemberList.styled';
import { FILTER_ROLES } from './constant';

type SearchFormValues = {
  search: string | null;
  roles: boolean[];
};

type WorkspaceMemberListProps = {
  memberList: Member[];
  getWorkspaceMemberList: (searchQueryParams: SearchQueryParams) => void;
  inviteType: 'embed' | 'team';
  paginationData?: PaginationDataType;
};

type PaginationDataType = {
  pageNo: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
};

function WorkspaceMemberList({
  memberList,
  getWorkspaceMemberList,
  inviteType,
  paginationData,
}: WorkspaceMemberListProps) {
  const { control, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      search: null,
      roles: [],
    },
  });

  const { removeAMemberFromAWorkSpace } = useRemoveMemberFromAWorkspace();
  const { updateAMemberRole } = useUpdateAMemberRole();
  const [, setSubscriptionPlan] = useAtom(subscriptionPlanAtom);
  const [role, setRole] = useState<Role>('');

  const canNextPage =
    !_isNil(paginationData) &&
    paginationData.pageNo < paginationData.totalPages;

  const canPreviousPage = !_isNil(paginationData) && paginationData.pageNo > 1;

  const memberName = useWatch({
    control,
    name: 'search',
  });

  const roles = useWatch({
    control,
    name: 'roles',
  });

  const { openWithProps: openInviteModal } = useLayer(
    <InviteModal inviteType={inviteType} />
  );

  useEffect(() => {
    let submitTimeout: TimerId;

    if (!_isNil(memberName) || roles.length > 0) {
      submitTimeout = setTimeout(() => {
        void onSearch(watch());
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [memberName, JSON.stringify(roles)]);

  const onClickRole = (roleDetails: RoleDetails) => {
    const { value } = roleDetails;
    setRole(value);
  };

  const getAccountUsageDetails = async () => {
    const response = await axiosPrivate.get<{
      data: SubscriptionPlanType;
    }>(`/plan/usage`);

    if (!_isNil(response.data.data)) {
      setSubscriptionPlan({
        ...response.data.data,
        planName: response.data.data.plan.name,
      });
    }
  };

  const onUpdateRole = async (roleDetails: RoleDetails, id?: string) => {
    try {
      await updateAMemberRole({
        role: roleDetails.value,
        user_uuid: id ?? '',
        type: inviteType,
      });
      setRole('');

      if (!isOnboardingCompleted(inviteUser)) {
        updateWidgetState(inviteUser)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }

      void getWorkspaceMemberList({ inviteType });
      void getAccountUsageDetails();
    } catch (error) {
      showErrorToast(error as AxiosError<CustomAxiosError>);
    }
  };

  const onSearch = async (formValues: SearchFormValues) => {
    const { search, roles } = formValues;
    const _roles = FILTER_ROLES.filter((_, index) => roles[index])
      .map((role) => role.value)
      .join(',');

    void getWorkspaceMemberList({
      search: search ?? '',
      roles: _roles,
      inviteType,
      pageNo: 1,
      pageSize: 10,
    });
  };

  const onRemoveClicked = async (userId: string) => {
    try {
      await removeAMemberFromAWorkSpace({ memberId: userId, inviteType });
      void getWorkspaceMemberList({ inviteType });
      void getAccountUsageDetails();
    } catch (error) {
      showErrorToast(error as AxiosError<CustomAxiosError>);
    }
  };

  const handleInviteSuccess = () => {
    void getWorkspaceMemberList({ inviteType, pageNo: 1, pageSize: 10 });
    void getAccountUsageDetails();
  };

  const formdata = watch();

  const fetchWorspaceMemberDataForPageNo = (pageNo = 1) => {
    const { search, roles } = formdata;

    const _roles = FILTER_ROLES.filter((_, index) => roles[index])
      .map((role) => role.value)
      .join(',');

    void getWorkspaceMemberList({
      search: search ?? '',
      roles: _roles,
      inviteType,
      pageNo,
      pageSize: 10,
    });
  };

  const goToPage = (num: number) => {
    void fetchWorspaceMemberDataForPageNo(num + 1);
  };

  const nextPage = () => {
    let num = 1;

    if (!_isNil(paginationData)) {
      num = paginationData.pageNo + 1;
    }
    void fetchWorspaceMemberDataForPageNo(num);
  };

  const previousPage = () => {
    let num = 1;

    if (!_isNil(paginationData)) {
      num = paginationData.pageNo - 1;
    }
    void fetchWorspaceMemberDataForPageNo(num);
  };

  const launcher = (
    <Inline gutter=".8rem" align="center">
      <Typography>
        {_isEmpty(role) ? 'All Roles' : _capitalize(role)}
      </Typography>
      <BiChevronDown />
    </Inline>
  );

  return (
    <>
      <MemberContainer align="center">
        <Typography fontWeight={700}>Members ({memberList?.length})</Typography>

        <form onSubmit={handleSubmit(onSearch)}>
          <Inline align="center">
            <InlineChildrenContainer>
              <PadBox
                padding={{
                  left: '.8rem',
                }}
              >
                <CiSearch />
              </PadBox>

              <TextFieldContainer width="30rem">
                <TextField
                  widthFull
                  name="search"
                  placeholder="Search people"
                  control={control}
                  showOutline={false}
                />
              </TextFieldContainer>

              <PadBox
                padding={{
                  right: '.8rem',
                }}
                style={{
                  borderLeft: '1px solid var(--color-lightGray8)',
                  paddingLeft: '0.5rem',
                }}
              >
                <FilterRoleUi
                  launcher={launcher}
                  onClickRole={onClickRole}
                  placement="bottom-end"
                  control={control}
                />
              </PadBox>
            </InlineChildrenContainer>
            <Button
              onClick={() =>
                openInviteModal({ inviteType, onSuccess: handleInviteSuccess })
              }
            >
              {inviteType === 'embed' ? 'Add users' : 'Invite users'}
            </Button>
          </Inline>
        </form>
      </MemberContainer>

      <div>
        <MemberListContainer gutter="1.6rem">
          {memberList.map(({ user, role, isInvitationVerified }) => {
            const { email, firstName, lastName, uuid } = user;
            const memberName = getUserName(firstName, lastName);
            const memberAvatar = getUserAvatar(firstName, lastName, email);

            return (
              <Fragment key={email}>
                <HorizontalLine color="--color-solitude" />

                <MemberContainer align="center">
                  <Inline align="center">
                    <ProfileIconContainer
                      align="center"
                      justify="center"
                      cursor="auto"
                    >
                      <Typography>{memberAvatar}</Typography>
                    </ProfileIconContainer>

                    <Stack gutter={0}>
                      <Inline gutter="1.6rem" justify="start">
                        {!_isEmpty(memberName) && (
                          <Typography fontWeight={700}>{memberName}</Typography>
                        )}

                        {!isInvitationVerified && (
                          <MemberStatus>Pending</MemberStatus>
                        )}
                      </Inline>

                      <Typography name="secondary2">{email}</Typography>
                    </Stack>
                  </Inline>

                  <RoleList
                    id={uuid}
                    launcher={
                      <Button
                        appearance="bordered-black"
                        disabled={role === 'owner'}
                      >
                        {_capitalize(role)}
                      </Button>
                    }
                    onClickRole={onUpdateRole}
                    placement="bottom-end"
                    onRemoveMember={async () => await onRemoveClicked(uuid)}
                    role={role}
                  >
                    <TextButton appearance="danger">Remove</TextButton>
                  </RoleList>
                </MemberContainer>
              </Fragment>
            );
          })}
        </MemberListContainer>
        <PaginationContainer>
          <Pagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageCount={paginationData?.totalPages ?? 1}
            pageIndex={!_isNil(paginationData) ? paginationData.pageNo - 1 : 1}
            goToPage={goToPage}
          />
        </PaginationContainer>
      </div>
    </>
  );
}

export const WorkspaceMemberListMemoized = memo(WorkspaceMemberList);
