import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useRef, useState } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useController, useWatch } from 'react-hook-form';
import {
  OperatorField,
  OperatorOnChange,
  OperatorsProps,
  PopoverMethods,
  PopoverPanel,
} from 'ui';

import { generateUid } from '../../../../../utils/common';
import { operators } from '../../../fixtures/operators';
import { isRuleReadOnlyAtom } from '../../../index';
import { sendEventToGTMType } from '../../../types';
import { getConstantNode, getOperatorTextById } from '../../../utils/common';
import { DecisionTableNodesModel } from '../models';
import { OperatorsLauncher } from './OperatorsLauncher';
import { PopoverContainerStyled } from './TableNodes.styled';

type OperatorSelectionProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
  index: number;
  conditionIndex: number;
  rowKey: string;
  conditionKey: string;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
  nodeCondition: Record<string, any>;
  nodeCurrentProperty: Record<string, any>;
  setNodes: (value: any) => void;
};

export function OperatorSelection({
  control,
  setValue,
  index,
  conditionIndex,
  rowKey,
  conditionKey,
  handleSendEventToGTM,
  nodeCondition,
  nodeCurrentProperty,
  setNodes,
}: OperatorSelectionProps) {
  const [filteredOperators, setFilteredOperators] = useState<OperatorsProps>(
    {}
  );
  const ref = useRef<PopoverMethods>(null);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const nodeName = `rows.${index}.${rowKey}.condition.${conditionIndex}.${conditionKey}`;
  const rhsNodeName = `rows.${index}.${rowKey}.condition.${conditionIndex}.rhs`;

  const { fieldState } = useController({
    name: nodeName,
    control,
  });

  // eslint-disable-next-line
  const rhsNodes = useWatch({
    name: rhsNodeName,
    control,
  });

  const currentConditionNode = useWatch({
    name: `rows.${index}.${rowKey}.condition.${conditionIndex}`,
    control,
  });

  const currentOperator = !_isNil(currentConditionNode[conditionKey])
    ? getOperatorTextById(
        operators,
        currentConditionNode[conditionKey].value,
        currentConditionNode[conditionKey].type
      )
    : undefined;

  const onOperatorClick = ({
    value,
    rightOperands,
    leftOperands,
    type,
  }: OperatorOnChange) => {
    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'selection',
        element: 'operator',
        actionName: type,
      });
    }

    setNodes((prev: any) => ({
      ...prev,
      [conditionKey]: {
        ...prev[conditionKey],
        operator: value,
      },
    }));

    const currentProperty = nodeCondition.leftNode?.[0];

    let propertyDataType =
      !_isNil(currentProperty) &&
      !_isEmpty(currentProperty) &&
      !_isNil(nodeCurrentProperty.dataType)
        ? nodeCurrentProperty.dataType
        : '';

    if (['in', 'nin', 'containsIn', 'notContainsIn'].includes(value)) {
      propertyDataType = 'list';
    }

    // if (rhsNodes?.length !== rightOperands) {
    const newRhsNodeList: string[] = [];
    const newRhsFieldList = [];

    for (let i = 0; i < rightOperands; i++) {
      const newRhsId = generateUid('rule_');
      const newRhsNode = getConstantNode(conditionKey, type);
      newRhsNodeList.push(newRhsId);

      newRhsFieldList.push({
        [newRhsId]: {
          value: '',
          key: '',
          dataType: propertyDataType ?? '',
        },
      });

      setNodes((prev: any) => ({
        ...prev,
        [newRhsId]: {
          ...newRhsNode,
          dataType: propertyDataType ?? '',
        },
      }));
    }

    const elementsToBeDeleted = nodeCondition.rightNode ?? [];

    setNodes((prev: any) => ({
      ...prev,
      [conditionKey]: {
        ...prev[conditionKey],
        rightNode: newRhsNodeList,
      },
    }));

    setNodes((prev: any) => {
      const newNodes: Record<string, DecisionTableNodesModel> = {};
      Object.keys(prev).forEach((id) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!elementsToBeDeleted.includes(id)) {
          newNodes[id] = prev[id];
        }
      });

      return newNodes;
    });

    setValue(rhsNodeName, newRhsFieldList);
    // }

    ref.current?.hide();
  };

  useEffect(() => {
    setFilteredOperators(
      _reduce(
        operators,
        (result: OperatorsProps, value, key) => {
          if (key === nodeCondition?.dataType || key === 'generic') {
            return { ...result, [key]: operators[key] };
          }

          return result;
        },
        {}
      )
    );
  }, [JSON.stringify(nodeCondition)]);

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      appendTo={document.body}
      launcher={
        <OperatorsLauncher
          error={fieldState.error?.message}
          text={!_isNil(currentOperator) ? currentOperator : 'Operator'}
          handleSendEventToGTM={handleSendEventToGTM}
          dataType={currentConditionNode[conditionKey].type}
        />
      }
      ref={ref}
      disabled={isRuleReadOnly}
    >
      <PopoverContainerStyled>
        <OperatorField
          name={nodeName}
          control={control}
          operators={filteredOperators}
          onClick={onOperatorClick}
        />
      </PopoverContainerStyled>
    </PopoverPanel>
  );
}
