import { useMutation } from '@tanstack/react-query';

import { useAxiosPrivate } from '../../../../hooks';
import type { Role } from '../../component/types';

export type useInviteMemberInAWorkspacePayload = {
  email: string;
  role: Role;
  inviteType: 'embed' | 'team';
  createDefaultWorkspace?: boolean;
};

export function useInviteMemberInAWorkspace() {
  const { axiosPrivate } = useAxiosPrivate();

  const {
    mutateAsync: inviteMemberInAWorkspace,
    isLoading,
    data,
    error,
  } = useMutation({
    mutationFn: async (request: useInviteMemberInAWorkspacePayload) => {
      const { email, role, inviteType, createDefaultWorkspace } = request;

      return await axiosPrivate.post('/workspace/member/invite', {
        email,
        role,
        type: inviteType,
        createDefaultWorkspace,
      });
    },
  });

  return {
    inviteMemberInAWorkspace,
    isLoading,
    data,
    error,
  };
}
