import { type UseControllerProps, type UseFormSetValue } from 'react-hook-form';

import DecisionTableHeaders from './DecisionTableHeaders';
import DecisionTableRows from './DecisionTableRows';

type DecisionTableBlockProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
};

export function DecisionTableBlock({
  control,
  setValue,
}: DecisionTableBlockProps) {
  return (
    <>
      <DecisionTableHeaders control={control} setValue={setValue} />
      <DecisionTableRows control={control} setValue={setValue} />
    </>
  );
}

// Update the prop types for the child components
type DecisionTableComponentProps = DecisionTableBlockProps & {
  columnWidths: Array<{
    id: string;
    width: number;
  }>;
};

// Re-export the updated types
export type { DecisionTableComponentProps };
