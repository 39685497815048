import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const ContainerStyled = styled(Stack)`
  border-top: 1px solid var(--color-lightGray8);
  padding: 1.5rem 0rem;
  margin: 1rem auto;

  .label-text {
    margin-bottom: 0.5rem;
  }

  .email-error {
    margin-top: 0.5rem;
  }
`;

export const HorizontalLine = styled.div`
  height: 0.5px;
  background-color: var(--color-darkGray);
  margin-top: 2rem;
`;

export const FieldContainer = styled(Inline)`
  flex: 1;
  border: 1px solid var(--color-gainsboro);
  border-radius: 0.4rem;
`;
export const WorkspaceOptionContainer = styled(Inline)`
  background-color: var(--color-primary3);
  border-radius: 0.4rem;
  padding: 1rem;
  margin-top: 2rem;
`;
