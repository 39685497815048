import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import type { CredentialResponse } from '@react-oauth/google';
import _isNil from 'lodash/isNil';
import { useSearchParams } from 'react-router-dom';
import { toasts } from 'ui';

import { generateCaptchaToken, logEvent } from '../../../utils/common';
import { envMap } from '../../../utils/constant';
import { useSocialSignin } from './useSocialSignin';
import { jwtDecode } from 'jwt-decode';

export function Google() {
  const { signinWithSocial } = useSocialSignin('google');
  const [searchParams] = useSearchParams();

  const onGetToken = (googleResponse: CredentialResponse) => {
    const { credential } = googleResponse;
    const source: Record<string, string> = JSON.parse(
      window.localStorage.getItem('source') ?? '{}'
    );

    let token: string | null = null;

    if (!_isNil(searchParams.get('token'))) {
      token = window.atob(searchParams.get('token') ?? '');
    }

    if (!_isNil(credential)) {
      const decoded : Record<string , any>= jwtDecode(credential);
      void logEvent('INIT-SUCCESS', 'google', decoded.email);
      generateCaptchaToken(signinWithSocial, {
        tokenId: credential,
        source,
        token,
      });
    }
  };

  const onclick = () => {
    void logEvent('INIT', 'google');
  };

  return (
    <GoogleOAuthProvider clientId={envMap.VITE_GOOGLE_CLIENT_ID}>
      <GoogleLogin
        click_listener={onclick}
        onSuccess={onGetToken}
        onError={() => {
          void logEvent('ERROR', 'google');
          toasts.error('Something went wrong.', 'something-went-wrong');
        }}
        logo_alignment="center"
        use_fedcm_for_prompt
      />
    </GoogleOAuthProvider>
  );
}
