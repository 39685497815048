import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import { currentWorkspaceDetailAtom } from '../Workspace/atom';
import { QuestionnaireForm } from './components/QuestionnaireForm';

export const Questionnaire = () => {
  sessionStorage.removeItem('questionnaireData');
  const { siteMeta } = useGetSiteMeta();
  const navigate = useNavigate();

  const [currWorkspaceDetails] = useAtom(currentWorkspaceDetailAtom);

  const workspaceRole = currWorkspaceDetails?.role ?? '';

  useEffect(() => {
    if (
      !['owner', 'admin'].includes(workspaceRole) &&
      !_isNil(currWorkspaceDetails) // added this check becase initial stage of currWorkspaceDetails can be null
    ) {
      navigate('/home');
    }
  }, [workspaceRole]);

  return (
    <>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <QuestionnaireForm />
    </>
  );
};
