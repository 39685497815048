import { PublicClientApplication } from '@azure/msal-browser';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toasts } from 'ui';

import { generateCaptchaToken, logEvent } from '../../../utils/common';
import { envMap } from '../../../utils/constant';
import { MSLoginButton } from './Social.styled';
import { useSocialSignin } from './useSocialSignin';
import { jwtDecode } from 'jwt-decode';

const msalConfig = {
  auth: {
    clientId: envMap.VITE_MICROSOFT_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${envMap.VITE_MICROSOFT_TENANT_ID}`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const loginRequest = {
  scopes: ['openid', 'profile', 'email', 'User.Read', 'offline_access'],
};

export function Microsoft() {
  const { signinWithSocial } = useSocialSignin('microsoftonline');
  const [searchParams] = useSearchParams();

  const source: Record<string, string> = JSON.parse(
    window.localStorage.getItem('source') ?? '{}'
  );
  const [msalInstance, setMsalInstance] =
    useState<PublicClientApplication | null>(null);

  useEffect(() => {
    const instance = new PublicClientApplication(msalConfig);
    instance
      .initialize()
      .then(() => {
        setMsalInstance(instance);
      })
      .catch((e) => {
        toasts.error(e.message, 'ms-loading-error');
      });
  }, []);

  const handleLogin = () => {
    void logEvent('INIT', 'microsoftonline');

    let token: string | null = null;

    if (!_isNil(searchParams.get('token'))) {
      token = window.atob(searchParams.get('token') ?? '');
    }

    if (!_isNil(msalInstance)) {
      msalInstance
        .loginPopup(loginRequest)
        .then((response) => {
          if (!_isNil(response)) {
            const decoded : Record<string , any>= jwtDecode(response.idToken);
            void logEvent('INIT-SUCCESS', 'microsoftonline' , decoded.email);
            generateCaptchaToken(signinWithSocial, {
              tokenId: response.idToken,
              accessToken: response.accessToken,
              source,
              token,
            });
          }
        })
        .catch((e) => {
          void logEvent('ERROR', 'microsoftonline');
          toasts.error(e.message, 'ms-login-error');
        });
    } else {
      toasts.error('MSAL instance is not initialized.', 'ms-login-error');
    }
  };

  return (
    <div>
      <MSLoginButton onClick={handleLogin} disabled={msalInstance == null}>
        <img src="/assets/konark/icons/ms_login.svg" />
      </MSLoginButton>
    </div>
  );
}
