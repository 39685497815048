import { Inline, PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

type DynamicWidthProp = {
  scrollStarted?: boolean;
};

export const RuleEditorContainer = styled(Inline)`
  border-block-start: 1px solid var(--color-darkGray);
  block-size: calc(100vh - 23.2rem);
`;

export const RuleNodesContainer = styled(PadBox)<DynamicWidthProp>`
  display: inline-block;
  margin-top: ${({ scrollStarted = false }) =>
    scrollStarted ? '0rem' : '5rem'};
`;

export const DatasetButtonStyled = styled.button.attrs((props) => ({
  type: 'button',
}))`
  min-inline-size: 10rem;
  border-radius: 4px;
  background-color: var(--color-white);
  border: 1px solid var(--color-lightGray);
  padding: 0.6rem 0.5rem;
  cursor: pointer;

  &.btn-disabled-interceptor {
    background: var(--color-white) !important;
    color: var(--color-lightGray) !important;
    cursor: progress !important;
  }
`;

export const TestButtonContainer = styled(Inline)`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;

export const RuleInputHeader = styled(Inline)<DynamicWidthProp>`
  position: ${({ scrollStarted = false }) =>
    scrollStarted ? 'initial' : 'fixed'};

  z-index: 100;
  height: 5rem;
  width: 65%;
  padding: 0.6rem 1.2rem;
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomInputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: flex-start;
  width: 50%;
  gap: 2rem;
`;

export const HeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: flex-end;
  width: 50%;
  gap: 2rem;
`;
