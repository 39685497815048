import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const MemberContainer = styled(Inline)`
  justify-content: space-between;
`;

export const MemberListContainer = styled(Stack)`
  block-size: calc(100vh - 42rem);
  overflow-y: auto;
`;

export const MemberStatus = styled.div`
  border: none;
  border-radius: 4px;
  padding: 0 0.4rem;
  color: var(--color-tangerine);
  background: var(--color-earlyDawn);
  font-size: 1.2rem;
`;

type TextFieldContainerProps = {
  width: string;
};

export const TextFieldContainer = styled(Inline)<TextFieldContainerProps>`
  width: ${({ width }) => width};
`;

export const PaginationContainer = styled(Inline)`
  justify-content: end;
  align-items: center;
  padding: 1.6rem;
`;
