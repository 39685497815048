import { getDataTypeNected } from 'ui';

import { TableDetailType } from './JsonInTableView';

export const getDetailsForTableView = (
  value: any,
  dataType: string,
  tableDetails: TableDetailType
): TableDetailType => {
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return tableDetails; // Return empty table details if the array is empty
    }

    const isListOfPrimitives = value.every((item) => typeof item !== 'object');
    const isListOfJson = value.every(
      (item) => typeof item === 'object' && !Array.isArray(item)
    );
    const isListOfList = value.every((item) => Array.isArray(item));

    if (isListOfPrimitives) {
      // Case 1: List of primitives
      tableDetails.header.push({ name: 'Values', dataType: 'list' });
      tableDetails.row.push({ Values: value.join(', ') });
    } else if (isListOfJson) {
      // Case 2: List of JSON (with different keys)
      const keyDataTypeMap: Record<string, string> = {};

      // Collect all unique keys and infer their data types
      value.forEach((item) => {
        Object.entries(item).forEach(([key, val]) => {
          if (!(key in keyDataTypeMap)) {
            keyDataTypeMap[key] = getDataTypeNected(val);
          } else if (keyDataTypeMap[key] !== getDataTypeNected(val)) {
            keyDataTypeMap[key] = 'mixed'; // If conflicting types, set as 'mixed'
          }
        });
      });

      // Generate headers using the inferred data types
      Object.entries(keyDataTypeMap).forEach(([key, type]) =>
        tableDetails.header.push({ name: key, dataType: type })
      );

      // Generate rows
      value.forEach((item) => {
        const row: Record<string, any> = {};
        tableDetails.header.forEach((header) => {
          if (header.name in item) {
            const currentValue = item[header.name];

            const isListOfPrimitives =
              typeof currentValue === 'object' &&
              Array.isArray(currentValue) &&
              currentValue.every((item: any) => typeof item !== 'object');

            if (isListOfPrimitives) {
              row[header.name] = currentValue.join(',');
            } else {
              row[header.name] = item[header.name];
            }
          } else {
            row[header.name] = '-'; // Fill missing keys with `-`
          }
        });
        tableDetails.row.push(row);
      });
    } else if (isListOfList) {
      // Case 3: List of lists
      tableDetails.header.push({ name: 'List', dataType: 'list' });
      tableDetails.row = value.map((currentValue, index) => {
        const isListOfPrimitives =
          Array.isArray(currentValue) &&
          currentValue.every((item) => typeof item !== 'object');

        return {
          List: isListOfPrimitives ? currentValue.join(',') : currentValue,
        };
      });
    } else {
      // Case 4: List of heterogeneous
      tableDetails.header.push({ name: 'Values', dataType: 'list' });
      tableDetails.row.push({
        Values: value,
        // value.join(', ')
      });
    }
  } else if (typeof value === 'object' && value !== null) {
    // Case 5: JSON object
    Object.keys(value).forEach((key) => {
      tableDetails.header.push({
        name: key,
        dataType: getDataTypeNected(value[key]),
      });
    });

    const row: Record<string, any> = {};
    Object.entries(value).forEach(([key, val]) => {
      const isListOfPrimitives =
        typeof val === 'object' &&
        Array.isArray(val) &&
        val.every((item: any) => typeof item !== 'object');

      row[key] = isListOfPrimitives ? val.join(',') : val;
    });
    tableDetails.row.push(row);
  } else {
    // For other data types, return as a single cell
    tableDetails.header.push({ name: 'Value', dataType });
    tableDetails.row.push({ Value: value });
  }

  return tableDetails;
};
