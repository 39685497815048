import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import AceEditor from 'react-ace';
import { JsonHeroTreeViewer } from 'ui';

import { JsonInTableView } from '../../../../../components/JsonInTableView/JsonInTableView';
import { ExecutionView } from '../../../../../components/jsonOutputField/components/ExecutionView';
import {
  JsonTreeContainer,
  OutputContainer,
} from '../SwitchNodeSheet/SwitchNodeSheet.styled';
import { StyledLink } from './LoopNodeTest.styled';

type LoopNodeTestProps = {
  outputValue?: Record<string, any>;
  executionId?: string;
};

export function LoopNodeTest({
  outputValue = {
    _comment: 'Please test the node first',
  },
  executionId,
}: LoopNodeTestProps) {
  const filter = window.btoa(
    JSON.stringify({
      apiURL: `/audit/audit/${executionId as string}?pageSize=10&pageNo=0`,
      tableHeaders: [],
    })
  );

  const link = `/auditlogs/${executionId as string}?filter=${filter}`;

  return (
    <PadBox padding={8}>
      <PadBox padding={8}>
        {!_isNil(executionId) && !_isEmpty(executionId) && (
          <StyledLink to={link} target="_blank">
            View records in audit trail
          </StyledLink>
        )}
      </PadBox>
      <OutputContainer>
        <Stack gutter="1rem">
          <ExecutionView>
            <AceEditor
              className="json-result-readonly"
              mode="json"
              theme="chrome"
              width="100%"
              fontSize={12}
              showPrintMargin={false}
              highlightActiveLine={true}
              showGutter={true}
              setOptions={{
                showLineNumbers: true,
                tabSize: 2,
              }}
              readOnly
              value={JSON.stringify(outputValue, null, 2)}
            />
            <JsonTreeContainer>
              <JsonHeroTreeViewer json={outputValue} />
            </JsonTreeContainer>

            <JsonTreeContainer>
              <JsonInTableView json={{ output: outputValue?.output ?? null }} />
            </JsonTreeContainer>
          </ExecutionView>
        </Stack>
      </OutputContainer>
    </PadBox>
  );
}
