import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { useEffect } from 'react';

import { useGetWorkspaceMemberList } from '../../hooks/restApi/useGetWorkspaceMemberList';
import { WorkspaceContainer } from '../common/Workspace.styled';
import { WorkspaceMemberListMemoized } from './WorkspaceMemberList';

export type InviteOptionsType = 'embed' | 'team';

type TeamProps = {
  inviteType: InviteOptionsType;
};

export function Team({ inviteType }: TeamProps) {
  const { data, getWorkspaceMemberList } = useGetWorkspaceMemberList();
  useEffect(() => {
    void getWorkspaceMemberList({ inviteType, pageNo: 1, pageSize: 10 });
  }, [inviteType]);

  return (
    <PadBox padding="2.4rem" gutter="2.4rem" as={WorkspaceContainer}>
      <Stack gutter="2rem">
        <WorkspaceMemberListMemoized
          memberList={data?.data ?? []}
          inviteType={inviteType}
          getWorkspaceMemberList={getWorkspaceMemberList}
          paginationData={data?.paginationData}
        />
      </Stack>
    </PadBox>
  );
}
