import { useAtom } from 'jotai';
import { useRef, useTransition } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { IconButton, Menu, MenuItem, PopoverMethods, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../../index';
import { sendEventToGTMType } from '../../../types';
import { useTableTransformWorker } from '../../../worker/useWorkerTableTransform';
import { decisionTableNodesAtom } from '../DecisionTable';

type RowActionsProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
  index: number;
  rowKey: string;
  addRowAtIndex: (index: number) => void;
  duplicateRowHandler: (index: number) => void;
  isLast: boolean;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export function RowActions({
  control,
  setValue,
  index,
  rowKey,
  addRowAtIndex,
  duplicateRowHandler,
  isLast,
  handleSendEventToGTM,
}: RowActionsProps) {
  const rows = useWatch({ name: 'rows', control });
  const { workerApi } = useTableTransformWorker();
  const [, startTransition] = useTransition();
  const ref = useRef<PopoverMethods>(null);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [nodes, setNodes] = useAtom(decisionTableNodesAtom);

  const menuItems = [
    {
      label: 'Add Row Above',
      value: 'addAbove',
    },
    {
      label: 'Add Row Below',
      value: 'addBelow',
    },
    {
      label: 'Delete',
      value: 'delete',
    },
  ];

  const handleRowDelete = () => {
    const newRows = rows.slice();
    newRows.splice(index, 1);
    setValue('rows', newRows);

    startTransition(() => {
      try {
        // eslint-disable-next-line
        workerApi.deleteDecisionTableNodes(rowKey, nodes).then((result) => {
          setNodes(result);
        });
      } catch (error) {
        // eslint-disable-next-line
        console.error('Processing error:', error);
      }
    });

    handleSendEventToGTM?.({
      action: 'delete',
      element: 'row',
      actionName: '',
    });
  };

  const launcher = <IconButton>⋮</IconButton>;

  return (
    <Menu
      placement="auto"
      launcher={launcher}
      onMenuItemClick={(value) => {
        if (value === 'delete' && !isLast) {
          handleRowDelete();
        } else if (value === 'addAbove') {
          addRowAtIndex(index);
        } else if (value === 'addBelow') {
          addRowAtIndex(index + 1);
        } else if (value === 'duplicate') {
          duplicateRowHandler(index);
        }
        ref.current?.hide();
      }}
      disabled={isRuleReadOnly}
      ref={ref}
      appendTo={document.body}
    >
      {menuItems.map((menuItem) => (
        <MenuItem value={menuItem.value} key={menuItem.value}>
          <Typography>{menuItem.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
}
