import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import { Navigate } from 'react-router-dom';
import { toasts } from 'ui';

import { Loader } from '../components/Loader/Loader.index';
import { roleJsonAtom } from '../components/authentication/router/AuthProvider';
import { currentWorkspaceDetailAtom } from '../pages/Workspace/atom';
import { ENTITY_ID } from '../utils/constant';
import { useGetRoutePermisisons } from './hooks/useGetRoutePermissions';

export const ComponentRoleWrapper = (
  Wrapper: () => JSX.Element,
  id: string,
  isEmailVerified: boolean,
  currentRoutePath: string
) => {
  const [roleJson] = useAtom(roleJsonAtom);
  const [currWorkspaceDetails] = useAtom(currentWorkspaceDetailAtom);

  const workspaceRole = currWorkspaceDetails?.role ?? '';

  useGetRoutePermisisons({ roleJson, routeId: id, currentRoutePath });

  const isEmptyJson = _isNil(roleJson) || _isEmpty(roleJson);

  const homePermissionsNotExist =
    id === 'home' && currentRoutePath.includes(id) && _isNil(roleJson.flags);

  const otherPermissionsNotExist =
    id !== 'home' &&
    (_isNil(roleJson.internals) ||
      (!_isNil(roleJson.internals) && _isUndefined(roleJson.internals[id])));

  if (isEmptyJson || otherPermissionsNotExist || homePermissionsNotExist) {
    return <Loader />;
  }

  if (!isEmailVerified && window.parent !== window) {
    return <Navigate to="/email-not-verified" />;
  }

  const props = !_isNil(roleJson.internals) ? roleJson.internals[id] : {};

  if (!_isNil(roleJson) && !_isNil(roleJson.flags) && !!roleJson.flags[id]) {
    return <Wrapper {...props} />;
  }

  let currentRouteId = '';

  if (currentRoutePath === '/') {
    currentRouteId = 'home';
  } else {
    currentRouteId = _reduce(
      ENTITY_ID,
      (acc, curr) => {
        return currentRoutePath.includes(curr) ? curr : acc;
      },
      ''
    );
  }

  const excludedRoutes = ['questionnaire', 'email-not-verified'];

  if (
    (!_isNil(roleJson?.flags) &&
      !roleJson.flags[currentRouteId] &&
      !excludedRoutes.includes(currentRouteId)) ||
    (excludedRoutes.includes(currentRouteId) &&
      !['owner', 'admin'].includes(workspaceRole))
  ) {
    toasts.error(
      `You don't have access. Contact owner or admin`,
      'not-access-error'
    );
  }

  return <Navigate to={'/home'} />;
};
