import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import _capitalize from 'lodash/capitalize';
import _isNil from 'lodash/isNil';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronDown } from 'react-icons/bi';
import {
  Button,
  CheckboxField,
  Modal,
  ModalContent,
  ModalFooter,
  TextField,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';
import { z } from 'zod';

import { InviteOptionsType } from '..';
import { getUserState } from '../../../../../hooks/getUserState';
import {
  isOnboardingCompleted,
  showErrorToast,
} from '../../../../../utils/common';
import { inviteUser } from '../../../../../utils/constant';
import { stopPropagate } from '../../../../../utils/form';
import { CustomAxiosError } from '../../../../../utils/response/types';
import { emailValidationSchema } from '../../../../../utils/schema';
import { updateWidgetState } from '../../../../Home/components/sub-components/UpdateWidgetState';
import { currentWorkspaceDetailAtom } from '../../../atom';
import {
  useInviteMemberInAWorkspace,
  useInviteMemberInAWorkspacePayload,
} from '../../../hooks/restApi/useInviteMemberInAWorkspace';
import { Role, RoleDetails } from '../../types';
import { RoleList } from '../RoleList';
import {
  ContainerStyled,
  FieldContainer,
  HorizontalLine,
  WorkspaceOptionContainer,
} from './InviteModal.styled';

type InviteModalProps = {
  inviteType: InviteOptionsType;
  onSuccess?: () => void;
};

type FormValues = {
  email: string;
  createDefaultWorkspace: boolean;
};

const formValidationSchema = z.object({
  ...emailValidationSchema,
  createDefaultWorkspace: z.boolean().optional(),
});

export const InviteModal = ({ inviteType, onSuccess }: InviteModalProps) => {
  const { close } = useCurrentLayer();

  const [currWorkspaceDetails] = useAtom(currentWorkspaceDetailAtom);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(formValidationSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      createDefaultWorkspace: false,
    },
  });

  const [role, setRole] = useState<Role>('Select Role');
  const [roleError, setRoleError] = useState<string>();

  const { inviteMemberInAWorkspace } = useInviteMemberInAWorkspace();

  const showWsCreationField =
    currWorkspaceDetails?.skipDefaultWorkspaceCreation === true &&
    inviteType === 'team';

  const workspaceName = currWorkspaceDetails?.name;

  const onClickRole = (role: RoleDetails) => {
    const { value } = role;
    setRole(value);
  };

  const sendInvite = async (formValues: FormValues) => {
    const { email, createDefaultWorkspace = false } = formValues;

    if (role === '' || role === 'Select Role') {
      setRoleError('Please select a role');

      return;
    } else {
      setRoleError(undefined);
    }

    const payload: useInviteMemberInAWorkspacePayload = {
      email,
      role,
      inviteType,
    };

    if (showWsCreationField) {
      payload.createDefaultWorkspace = createDefaultWorkspace;
    }

    try {
      const response = await inviteMemberInAWorkspace(payload);

      if (!isOnboardingCompleted(inviteUser)) {
        updateWidgetState(inviteUser)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }

      if (response?.data?.code === 'success') {
        toasts.success(
          inviteType === 'embed' ? 'User added' : 'Invite sent',
          'success'
        );

        setValue('email', '');
        setRole('Select Role');

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
        close();
      }
    } catch (error: unknown) {
      showErrorToast(error as AxiosError<CustomAxiosError>);
    }
  };

  const launcher = (
    <Inline gutter=".8rem" align="center">
      <Typography>{_capitalize(role)}</Typography>
      <BiChevronDown />
    </Inline>
  );

  const handleFormSubmit = stopPropagate(
    handleSubmit(async (data) => await sendInvite(data))
  );

  return (
    <Modal
      size="large"
      title={`${inviteType === 'embed' ? 'Add members' : 'Invite members'} to ${
        workspaceName ?? ''
      }`}
      overflow="none"
    >
      <ModalContent>
        <ContainerStyled gutter=".8rem">
          <form>
            <Typography className="label-text">Email Address</Typography>
            <Inline align="center">
              <FieldContainer>
                <TextField
                  widthFull
                  name="email"
                  placeholder={`${
                    inviteType === 'embed' ? 'Add user' : 'Invite user'
                  } via email e.g. abc@nected.ai`}
                  control={control}
                  showOutline={false}
                  showError={false}
                  showErrorIcon={false}
                />
              </FieldContainer>

              <PadBox
                padding={['0.8rem', '1.6rem']}
                style={{
                  border: '1px solid var(--color-gainsboro)',
                  borderRadius: '0.4rem',
                }}
              >
                <RoleList
                  launcher={launcher}
                  onClickRole={onClickRole}
                  placement="bottom-start"
                />
              </PadBox>
            </Inline>
            {!_isNil(errors.email) && (
              <Typography className="email-error" name="error">
                {errors.email.message}
              </Typography>
            )}

            {!_isNil(roleError) && (
              <Inline justify="end">
                <Typography className="email-error" name="error">
                  {roleError}
                </Typography>
              </Inline>
            )}

            <HorizontalLine />

            {showWsCreationField && (
              <WorkspaceOptionContainer>
                <Stack gutter="0.8rem">
                  <Typography>Create a separate workspace</Typography>
                  <Typography name="secondarySmall">
                    Enabling this creates a separate workspace for the invited
                    user while adding them to the current one.
                  </Typography>
                </Stack>

                <CheckboxField
                  name={'createDefaultWorkspace'}
                  appearance="switch"
                  control={control}
                  useId={'createDefaultWorkspace'}
                />
              </WorkspaceOptionContainer>
            )}
          </form>
        </ContainerStyled>
      </ModalContent>
      <ModalFooter>
        <Button type="submit" onClick={handleFormSubmit}>
          {inviteType === 'embed' ? 'Add User' : 'Send Invite'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
